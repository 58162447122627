import React, { useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableHead, TableRow, Typography, Pagination, Stack, CircularProgress, TableContainer, Paper } from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../services/api';

const Bills = () => {
  const [bills, setBills] = useState([]);
  const [page, setPage] = useState(1); // 当前页码
  const [totalPages, setTotalPages] = useState(1); // 总页数
  const [loading, setLoading] = useState(false); // 加载状态

  const fetchBills = async (currentPage) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token'); // 从 localStorage 获取 token

      const response = await axios.get(`${API_BASE_URL}/payment/bills`, {
        params: {
          page: currentPage,
          limit: 10, // 每页显示 10 条
        },
        headers: {
          Authorization: `Bearer ${token}`, // 将 token 添加到请求头
        },
      });

      // 设置账单数据和分页信息
      setBills(response.data.transactions);
      setTotalPages(response.data.pagination.totalPages);
    } catch (err) {
      console.error('获取账单失败:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBills(page); // 初始加载第一页账单
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value); // 设置新的页码
  };

  return (
    <Container maxWidth="lg" sx={{ marginTop: '5vh' }}>
      <Typography variant="h5" align="center" gutterBottom>
        账单记录
      </Typography>

      <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
        <Table sx={{ minWidth: 650, maxWidth: '100%' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#1976d2' }}>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>订单号</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>金额 (元)</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>类型</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>时间</TableCell>
              <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>状态</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : bills.length > 0 ? (
              bills.map((bill) => (
                <TableRow key={bill._id} hover sx={{ backgroundColor: '#f9f9f9', '&:hover': { backgroundColor: '#e0f7fa' } }}>
                  <TableCell>{bill.outTradeNo}</TableCell>
                  <TableCell>{bill.totalAmount}</TableCell>
                  <TableCell>{bill.subject}</TableCell>
                  <TableCell>{new Date(bill.createdAt).toLocaleString()}</TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 'bold',
                        color: bill.status === 'completed' ? 'green' : 'orange',
                      }}
                    >
                      {bill.status === 'completed' ? '成功' : '待处理'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  暂无账单记录
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* 分页控件 */}
      <Stack spacing={2} sx={{ marginTop: 2 }} alignItems="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          variant="outlined"
        />
      </Stack>
    </Container>
  );
};

export default Bills;
