import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isLoggedIn, role, allowedRoles, children }) => {
  // 如果用户未登录，重定向到登录页面
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  // 如果用户已登录但没有权限，重定向到根路径 "/"
  if (isLoggedIn && !allowedRoles.includes(role)) {
    return <Navigate to="/" />;
  }

  // 如果用户已登录并且有权限，渲染子组件
  return children;
};

export default ProtectedRoute;
