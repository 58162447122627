import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
    Typography,
    Box,
    TextField,
    Button,
    Grid,
    CircularProgress,
    Alert,
    Pagination,
    Card,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../services/api';
import ReCAPTCHA from 'react-google-recaptcha';

const IPCorrection = () => {
    const [corrections, setCorrections] = useState([]); // 存储纠错信息
    const [loading, setLoading] = useState(true); // 加载状态
    const [startIP, setStartIP] = useState(''); // 起始IP
    const [endIP, setEndIP] = useState(''); // 结束IP
    const [location, setLocation] = useState(''); // 地理位置
    const [feedback, setFeedback] = useState(''); // 反馈信息
    const [recaptchaToken, setRecaptchaToken] = useState(''); // reCAPTCHA token
    const [error, setError] = useState(''); // 提交时的错误信息
    const [success, setSuccess] = useState(false); // 提交成功状态
    const [page, setPage] = useState(1); // 当前页
    const [totalPages, setTotalPages] = useState(1); // 总页数

    const recaptchaRef = useRef(null); // 用于引用 reCAPTCHA 组件实例

    const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    useEffect(() => {
        fetchCorrections(page); // 获取当前页的纠错信息
    }, [page]); // 监听page变化，每次page变化时重新获取纠错信息

    // 获取所有纠错信息，支持分页
    const fetchCorrections = async (page) => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/ipCorrection/corrections`, {
                params: { page, limit: 5 }, // 每页显示5条数据
            });
            setCorrections(response.data.data);
            setTotalPages(response.data.totalPages);
            setLoading(false);
        } catch (err) {
            console.error('获取纠错信息失败:', err);
            setLoading(false);
        }
    };

    // 提交纠错信息
    const handleSubmit = async () => {
        // 验证 startIP 格式是否为 IPv4
        if (!startIP || !ipv4Regex.test(startIP)) {
            setError('请输入有效的起始 IPv4 地址');
            resetRecaptcha();
            return;
        }

        // 如果填写了 endIP，检查是否为 IPv4 格式
        if (endIP && !ipv4Regex.test(endIP)) {
            setError('请输入有效的结束 IPv4 地址');
            resetRecaptcha();
            return;
        }

        // 验证是否填写了地理位置和 reCAPTCHA
        if (!location || !recaptchaToken) {
            setError('请填写所有必填项，并通过 reCAPTCHA 验证');
            resetRecaptcha();
            return;
        }

        // 提交纠错信息
        try {
            const response = await axios.post(`${API_BASE_URL}/ipCorrection/submit-correction`, {
                startIP,
                endIP,
                location,
                feedback,
                recaptchaToken,
            });
            setSuccess(true);
            setError('');
            setStartIP('');
            setEndIP('');
            setLocation('');
            setFeedback('');
            setRecaptchaToken(''); // 清空 reCAPTCHA token
            fetchCorrections(page); // 刷新纠错信息列表
        } catch (err) {
            const errorMessage = err.response?.data?.message || '提交纠错信息失败，请重试';
            setError(errorMessage);
        }

        resetRecaptcha(); // 提交成功或失败后重置验证码
    };

    // 处理分页
    const handlePageChange = (event, value) => {
        setPage(value); // 更新当前页
    };

    // 处理 reCAPTCHA 验证成功后的回调
    const onRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    // 重置 reCAPTCHA
    const resetRecaptcha = () => {
        if (recaptchaRef.current) {
            recaptchaRef.current.reset(); // 调用 reCAPTCHA 组件的重置方法
        }
    };

    return (
        <Container maxWidth="lg">
            <Box sx={{ mt: 5, mb: 5 }}>
                <Grid container spacing={3}>
                    {/* 左边显示已提交的纠错信息 */}
                    <Grid item xs={12} md={7}>
                        <Typography variant="h5" gutterBottom>
                            已提交的纠错信息
                        </Typography>

                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <List>
                                {corrections.map((correction) => (
                                    <ListItem key={correction._id} sx={{ backgroundColor: '#f5f5f5', mb: 2, borderRadius: 2 }}>
                                        <ListItemText
                                            primary={`提交的IP: ${correction.startIP} - ${correction.endIP || correction.startIP}`}
                                            secondary={
                                                <>
                                                    <Typography variant="body2" color="textSecondary">
                                                        提交时间：{new Date(correction.createdAt).toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' })}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        状态：{correction.status}
                                                    </Typography>

                                                    {correction.status === '待审核' ? (
                                                        <Typography color="textSecondary">审核前不展示位置信息</Typography>
                                                    ) : (
                                                        <Box mt={2}>
                                                            <Typography variant="body2">提交的地理位置信息: {correction.location}</Typography>
                                                            <Typography variant="body2">实际更新信息: {correction.actualUpdate || '无'}</Typography>
                                                        </Box>
                                                    )}
                                                </>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        )}

                        {corrections.length === 0 && !loading && (
                            <Typography color="textSecondary">暂无纠错信息</Typography>
                        )}

                        {/* 分页组件 */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                            <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
                        </Box>

                        {/* 返回查询页面按钮 */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                href="https://ip.setonink.com"
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                返回查询页面
                            </Button>
                        </Box>
                    </Grid>

                    {/* 右边提交纠错表单 */}
                    <Grid item xs={12} md={5}> {/* 改为5，使表单宽度更大 */}
                        <Card sx={{ p: 3, backgroundColor: '#fafafa', boxShadow: 3, width: '100%' }}> {/* 保持100%宽度 */}
                            <Typography variant="h5" gutterBottom>
                                提交IP纠错
                            </Typography>

                            {/* 提示信息 */}
                            <Typography variant="body2" color="textSecondary" gutterBottom sx={{ mb: 3 }}>
                                1. 目前IPv6及AS信息暂不提供反馈通道；
                                <br />
                                2. IP所有者信息优先参考IP WHOIS，其次参考ASN；
                                <br />
                                3. 审核之后，通常将在24小时内更新至IP库，接口将同步生效；
                                <br />
                                4. 付费接口将在更新后同步生效，开放接口将在3-4天内同步生效；
                            </Typography>

                            {error && (
                                <Alert severity="error" sx={{ mb: 2 }}>
                                    {error}
                                </Alert>
                            )}

                            {success && (
                                <Alert severity="success" sx={{ mb: 2 }}>
                                    纠错信息提交成功！
                                </Alert>
                            )}

                            <TextField
                                label="起始IP"
                                fullWidth
                                value={startIP}
                                onChange={(e) => setStartIP(e.target.value)}
                                variant="outlined"
                                sx={{ mb: 2 }}
                                required
                            />

                            <TextField
                                label="结束IP（可选）"
                                fullWidth
                                value={endIP}
                                onChange={(e) => setEndIP(e.target.value)}
                                variant="outlined"
                                sx={{ mb: 2 }}
                            />

                            <TextField
                                label="地理位置"
                                fullWidth
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                variant="outlined"
                                sx={{ mb: 2 }}
                                required
                            />

                            <TextField
                                label="反馈信息（可选）"
                                fullWidth
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                variant="outlined"
                                sx={{ mb: 2 }}
                                multiline
                                rows={3}
                            />

                            {/* reCAPTCHA 验证 */}
                            <ReCAPTCHA
                                ref={recaptchaRef} // 通过 ref 引用组件实例
                                sitekey="6LeLbw4qAAAAAIPFN7f3NrPVosRGLlDtt3gkmHFn"
                                onChange={onRecaptchaChange}
                                hl="zh-CN"
                                theme="light"
                                size="normal"
                                scriptProps={{
                                    async: true,
                                    defer: true,
                                    appendTo: 'head',
                                    nonce: undefined,
                                    src: 'https://recaptcha.net/recaptcha/api.js',
                                }}
                            />

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                fullWidth
                                sx={{ mt: 2 }}
                                disabled={!recaptchaToken} // 在 reCAPTCHA 完成之前禁用按钮
                            >
                                提交纠错
                            </Button>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default IPCorrection;
