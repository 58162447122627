import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, Box, CircularProgress, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../services/api';

const MyPackages = () => {
  const [packages, setPackages] = useState([]); // 存储用户已购买的套餐信息
  const [loading, setLoading] = useState(true); // 加载状态
  const [openDialog, setOpenDialog] = useState(false); // 控制弹出框的状态
  const [currentPackageId, setCurrentPackageId] = useState(null); // 当前编辑的套餐ID
  const [whitelistIPs, setWhitelistIPs] = useState(''); // 存储用户输入的白名单 IP
  const [copySuccess, setCopySuccess] = useState([]); // 用于管理每个 token 的复制状态
  const hiddenInputRef = useRef(null); // 引用隐藏的输入框

  const fetchPackages = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${API_BASE_URL}/mypackage/my-packages`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPackages(response.data.userPackages);
      setCopySuccess(new Array(response.data.userPackages.length).fill(false)); // 初始化复制状态
      setLoading(false);
    } catch (err) {
      console.error('获取已购套餐失败:', err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackages(); // 调用 fetchPackages
  }, []);

  const handleResetToken = async (packageId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${API_BASE_URL}/mypackage/reset-token/${packageId}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('Token重置成功: ' + response.data.newToken);
      fetchPackages(); // 重置 token 后重新获取套餐信息
    } catch (err) {
      console.error('重置Token失败:', err);
    }
  };

  const handleOpenWhitelistDialog = async (packageId) => {
    setCurrentPackageId(packageId);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${API_BASE_URL}/mypackage/whitelist/${packageId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const existingIPs = response.data.whitelistIPs;
      setWhitelistIPs(existingIPs.join('\n')); // 使用换行符显示多个 IP
      setOpenDialog(true); // 打开弹出框
    } catch (err) {
      console.error('获取白名单 IP 失败:', err);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveWhitelist = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${API_BASE_URL}/mypackage/set-whitelist/${currentPackageId}`, {
        whitelistIPs: whitelistIPs ? whitelistIPs.split('\n') : []
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('白名单 IP 设置成功');
      fetchPackages(); // 设置白名单后重新获取套餐信息
      setOpenDialog(false); // 关闭弹出框
    } catch (err) {
      console.error('设置白名单 IP 失败:', err);
    }
  };

  const isExpired = (validity) => {
    return new Date(validity) < new Date();
  };

  const formatToken = (token) => {
    if (token.length > 15) {
      return `${token.slice(0, 6)}...${token.slice(-6)}`;
    }
    return token;
  };

  const handleCopyToken = (token, index) => {
    // 将 token 插入到隐藏的输入框中
    hiddenInputRef.current.value = token;
    hiddenInputRef.current.select();
    document.execCommand('copy'); // 执行复制命令

    // 设置对应 token 的复制成功状态
    const newCopySuccess = [...copySuccess];
    newCopySuccess[index] = true;
    setCopySuccess(newCopySuccess);

    // 2秒后清除提示
    setTimeout(() => {
      newCopySuccess[index] = false;
      setCopySuccess(newCopySuccess);
    }, 2000);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg"> {/* 更大的宽度 */}
      <Box sx={{ mt: 5 }}>
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            fontWeight: 'bold',
            color: '#1976d2',
            textTransform: 'uppercase', // 字母大写
            letterSpacing: '0.1em', // 增加字母间距
          }}
        >
          我的套餐
        </Typography>

        <Table
          sx={{
            minWidth: 900, // 增加表格宽度
            borderCollapse: 'separate',
            borderSpacing: '0 15px', // 行间距
            '& th': {
              backgroundColor: '#f5f5f5',
              fontWeight: 'bold',
              color: '#666',
              whiteSpace: 'nowrap', // 禁止换行
            },
            '& td': {
              backgroundColor: '#fff',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)', // 阴影效果
              padding: '16px', // 调整内边距
              whiteSpace: 'nowrap', // 禁止换行
            },
            '& tr:hover': {
              backgroundColor: '#f0f0f0', // 悬停效果
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>套餐名称</TableCell>
              <TableCell>总调用次数</TableCell>
              <TableCell>剩余调用次数</TableCell>
              <TableCell>有效期至</TableCell>
              <TableCell>状态</TableCell>
              <TableCell>调用 Token</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packages.map((pkg, index) => (
              <TableRow key={index}>
                <TableCell>{pkg.planName}</TableCell>
                <TableCell>{pkg.totalCalls}</TableCell>
                <TableCell>{pkg.callsRemaining}</TableCell>
                <TableCell>{new Date(pkg.validity).toLocaleDateString('zh-CN')}</TableCell>
                <TableCell>
                  {isExpired(pkg.validity) ? (
                    <Typography color="error" sx={{ fontWeight: 'bold' }}>
                      已过期
                    </Typography>
                  ) : (
                    <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                      使用中
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="点击复制">
                    <span onClick={() => handleCopyToken(pkg.token, index)} style={{ cursor: 'pointer', color: '#1976d2', fontWeight: 'bold' }}>
                      {formatToken(pkg.token)}
                    </span>
                  </Tooltip>
                  {copySuccess[index] && <Typography variant="body2" color="success">复制成功!</Typography>}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      mr: 2,
                      padding: '6px 16px',
                      fontSize: '0.875rem',
                      boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
                      transition: 'background-color 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#d32f2f',
                      },
                    }}
                    onClick={() => handleResetToken(pkg._id)}
                    disabled={isExpired(pkg.validity)}
                  >
                    重置 TOKEN
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      padding: '6px 16px',
                      fontSize: '0.875rem',
                      boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
                      transition: 'background-color 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#1565c0',
                      },
                    }}
                    onClick={() => handleOpenWhitelistDialog(pkg._id)}
                    disabled={isExpired(pkg.validity)}
                  >
                    设置白名单 IP
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* 白名单 IP 设置弹出框 */}
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
          <DialogTitle sx={{ fontWeight: 'bold', color: '#1976d2' }}>设置白名单 IP</DialogTitle>
          <DialogContent>
            <TextField
              label="白名单 IP (每行一个)"
              fullWidth
              multiline
              rows={8}
              variant="outlined"
              value={whitelistIPs}
              onChange={(e) => setWhitelistIPs(e.target.value)}
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between', padding: 2 }}>
            <Button onClick={handleCloseDialog} sx={{ color: '#f44336' }}>
              取消
            </Button>
            <Button onClick={handleSaveWhitelist} sx={{ color: '#1976d2' }}>
              保存
            </Button>
          </DialogActions>
        </Dialog>

        {/* 隐藏的输入框，用于手动复制 token */}
        <input ref={hiddenInputRef} type="text" style={{ position: 'absolute', left: '-9999px' }} />
      </Box>
    </Container>
  );
};

export default MyPackages;
