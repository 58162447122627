import React, { useState } from 'react';
import { Container, Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, Paper, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';

const StyledTable = styled(Table)(({ theme }) => ({
  '& .MuiTableCell-head': {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
  },
  '& .MuiTableCell-root': {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiTableRow-root:last-child .MuiTableCell-root': {
    borderBottom: 'none',
  },
}));

const DocPage = () => {
  const [selectedDoc, setSelectedDoc] = useState('ip-query'); // 默认选择IP查询文档

  const handleDocChange = (event) => {
    setSelectedDoc(event.target.value); // 选择文档类型
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5, mb: 10 }}>
      {/* 文档选择下拉菜单 */}
      <FormControl fullWidth sx={{ mb: 4 }}>
        <InputLabel id="doc-select-label">选择文档</InputLabel>
        <Select
          labelId="doc-select-label"
          value={selectedDoc}
          onChange={handleDocChange}
          label="选择文档"
        >
          <MenuItem value="ip-query">IP查询接口文档</MenuItem>
          <MenuItem value="domain-whois">域名Whois查询接口文档</MenuItem>
          <MenuItem value="icp-query">ICP备案查询接口文档</MenuItem>
        </Select>
      </FormControl>

      {/* IP 查询接口文档 */}
      {selectedDoc === 'ip-query' && (
        <>
          <Typography variant="h4" gutterBottom>
            IP查询接口文档
          </Typography>

          {/* 请求方式 */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              请求方式
            </Typography>
            <Paper elevation={3} sx={{ p: 2 }}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell>HTTP 方法</TableCell>
                    <TableCell>请求地址</TableCell>
                    <TableCell>是否需要认证</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>GET</TableCell>
                    <TableCell>
                      <a href="https://openapi.ip.setonink.com/ipdata?token=YOUR_TOKEN&ip=TARGET_IP&type=json" target="_blank" rel="noopener noreferrer">
                        https://openapi.ip.setonink.com/ipdata
                      </a><br />
                      <Typography variant="body2">
                        请求地址格式: https://openapi.ip.setonink.com/ipdata?token=YOUR_TOKEN&ip=TARGET_IP&type=FORMAT
                      </Typography>
                    </TableCell>
                    <TableCell>是</TableCell>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </Paper>
          </Box>

          {/* 请求参数 */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              请求参数
            </Typography>
            <Paper elevation={3} sx={{ p: 2 }}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell>参数名称</TableCell>
                    <TableCell>类型</TableCell>
                    <TableCell>是否必须</TableCell>
                    <TableCell>说明</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>ip</TableCell>
                    <TableCell>string</TableCell>
                    <TableCell>是</TableCell>
                    <TableCell>需要查询的目标IP地址，支持IPv4和IPv6格式。</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>token</TableCell>
                    <TableCell>string</TableCell>
                    <TableCell>是</TableCell>
                    <TableCell>用户的API调用凭证，直接添加到请求地址中的查询参数。</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>type</TableCell>
                    <TableCell>string</TableCell>
                    <TableCell>是</TableCell>
                    <TableCell>返回数据的格式，支持json、xml、txt、jsonp。</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>callback</TableCell>
                    <TableCell>string</TableCell>
                    <TableCell>否 (仅对 jsonp 有效)</TableCell>
                    <TableCell>当 type 为 jsonp 时，callback 参数指定返回的 JSONP 回调函数名称。</TableCell>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </Paper>
          </Box>

          {/* 请求示例 */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              请求示例
            </Typography>
            <Paper elevation={3} sx={{ p: 2, whiteSpace: 'pre-wrap', backgroundColor: '#f9f9f9' }}>
              <Typography variant="body1">
                <strong>JSON 格式请求:</strong>
              </Typography>
              <Box sx={{ mt: 2, fontFamily: 'monospace' }}>
                {`GET https://openapi.ip.setonink.com/ipdata?token=YOUR_TOKEN&ip=223.5.5.5&type=json`}
              </Box>

              <Typography variant="body1" sx={{ mt: 3 }}>
                <strong>XML 格式请求:</strong>
              </Typography>
              <Box sx={{ mt: 2, fontFamily: 'monospace' }}>
                {`GET https://openapi.ip.setonink.com/ipdata?token=YOUR_TOKEN&ip=223.5.5.5&type=xml`}
              </Box>

              <Typography variant="body1" sx={{ mt: 3 }}>
                <strong>TXT 格式请求:</strong>
              </Typography>
              <Box sx={{ mt: 2, fontFamily: 'monospace' }}>
                {`GET https://openapi.ip.setonink.com/ipdata?token=YOUR_TOKEN&ip=223.5.5.5&type=txt`}
              </Box>

              <Typography variant="body1" sx={{ mt: 3 }}>
                <strong>JSONP 格式请求:</strong>
              </Typography>
              <Box sx={{ mt: 2, fontFamily: 'monospace' }}>
                {`GET https://openapi.ip.setonink.com/ipdata?token=YOUR_TOKEN&ip=223.5.5.5&type=jsonp&callback=YOUR_CALLBACK`}
              </Box>
            </Paper>
          </Box>

          {/* 返回示例 */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              返回示例
            </Typography>
            <Paper elevation={3} sx={{ p: 2, whiteSpace: 'pre-wrap', backgroundColor: '#f9f9f9' }}>
              <Typography variant="body1">
                <strong>JSON:</strong>
              </Typography>
              <Box sx={{ mt: 2, fontFamily: 'monospace' }}>
                {`{
    "ret": "ok",  // 返回状态，"ok" 表示查询成功
    "data": {
        "ip": "223.5.5.5",  // 查询的目标IP地址
        "as": {  
            "number": 37963,  // 自治系统编号（AS号），表示IP所属的网络运营商编号
            "name": "Hangzhou Alibaba Advertising Co.,Ltd.",  // 自治系统名称，指该IP所属的组织或公司名称
            "info": "阿里云"  // 自治系统的附加信息，提供进一步的描述或说明
        },
        "addr": "223.4.0.0/14",  // IP地址所属的IP地址段
        "country": {
            "code": "CN",  // IP所在国家的代码（ISO 3166-1标准），此处是中国（CN）
            "name": "中国"  // IP所在国家的名称
        },
        "registered_country": {
            "code": "CN",  // 注册国家代码，表示该IP在注册时所在的国家代码
            "name": "中国"  // 注册国家名称
        },
        "regions": [
            "浙江省",  // 省份名称，表示IP的地理位置所属的省份
            "杭州市"  // 城市名称，表示IP的地理位置所属的城市
        ],
        "regions_short": [
            "浙江",  // 简化后的省份名称
            "杭州"  // 简化后的城市名称
        ],
        "type": "数据中心"  // IP地址的类型，可能是"宽带"、"数据中心"等，表示IP的使用场景
    }
}`}
              </Box>

              <Typography variant="body1" sx={{ mt: 3 }}>
                <strong>XML:</strong>
              </Typography>
              <Box sx={{ mt: 2, fontFamily: 'monospace' }}>
                {`<response>
    <ret>ok</ret>
    <data>
        <ip>223.5.5.5</ip>
        <as>
            <number>37963</number>
            <name>Hangzhou Alibaba Advertising Co.,Ltd.</name>
            <info>阿里云</info>
        </as>
        <addr>223.4.0.0/14</addr>
        <country>
            <code>CN</code>
            <name>中国</name>
        </country>
        <registered_country>
            <code>CN</code>
            <name>中国</name>
        </registered_country>
        <regions>
            <region>浙江省</region>
            <region>杭州市</region>
        </regions>
        <regions_short>
            <region>浙江</region>
            <region>杭州</region>
        </regions_short>
        <type>数据中心</type>
    </data>
</response>`}
              </Box>

              <Typography variant="body1" sx={{ mt: 3 }}>
                <strong>TXT:</strong>
              </Typography>
              <Box sx={{ mt: 2, fontFamily: 'monospace' }}>
                {`223.5.5.5 Hangzhou Alibaba Advertising Co.,Ltd. 中国 浙江省 杭州市 数据中心`}
              </Box>

              <Typography variant="body1" sx={{ mt: 3 }}>
                <strong>JSONP:</strong>
              </Typography>
              <Box sx={{ mt: 2, fontFamily: 'monospace' }}>
                {`yourCallbackFunction({
    "ret": "ok",
    "data": {
        "ip": "223.5.5.5",
        "as": {
            "number": 37963,
            "name": "Hangzhou Alibaba Advertising Co.,Ltd.",
            "info": "阿里云"
        },
        "addr": "223.4.0.0/14",
        "country": {
            "code": "CN",
            "name": "中国"
        },
        "registered_country": {
            "code": "CN",
            "name": "中国"
        },
        "regions": [
            "浙江省",
            "杭州市"
        ],
        "regions_short": [
            "浙江",
            "杭州"
        ],
        "type": "数据中心"
    }
});`}
              </Box>
            </Paper>
          </Box>
        </>
      )}

      {/* 域名 Whois 查询接口文档 */}
      {selectedDoc === 'domain-whois' && (
        <>
          <Typography variant="h4" gutterBottom>
            域名Whois查询接口文档
          </Typography>

          {/* 请求方式 */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              请求方式
            </Typography>
            <Paper elevation={3} sx={{ p: 2 }}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell>HTTP 方法</TableCell>
                    <TableCell>请求地址</TableCell>
                    <TableCell>是否需要认证</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>GET</TableCell>
                    <TableCell>
                      <a href="https://openapi.ip.setonink.com/domain-whois?token=YOUR_TOKEN&domain=TARGET_DOMAIN" target="_blank" rel="noopener noreferrer">
                        https://openapi.ip.setonink.com/domain-whois
                      </a><br />
                      <Typography variant="body2">
                        请求地址格式: https://openapi.ip.setonink.com/domain-whois?token=YOUR_TOKEN&domain=TARGET_DOMAIN
                      </Typography>
                    </TableCell>
                    <TableCell>是</TableCell>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </Paper>
          </Box>

          {/* 请求参数 */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              请求参数
            </Typography>
            <Paper elevation={3} sx={{ p: 2 }}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell>参数名称</TableCell>
                    <TableCell>类型</TableCell>
                    <TableCell>是否必须</TableCell>
                    <TableCell>说明</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>domain</TableCell>
                    <TableCell>string</TableCell>
                    <TableCell>是</TableCell>
                    <TableCell>需要查询的域名。</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>token</TableCell>
                    <TableCell>string</TableCell>
                    <TableCell>是</TableCell>
                    <TableCell>用户的API调用凭证，直接添加到请求地址中的查询参数。</TableCell>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </Paper>
          </Box>

          {/* 请求示例 */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              请求示例
            </Typography>
            <Paper elevation={3} sx={{ p: 2, whiteSpace: 'pre-wrap', backgroundColor: '#f9f9f9' }}>
              <Typography variant="body1">
                <strong>Whois 请求示例:</strong>
              </Typography>
              <Box sx={{ mt: 2, fontFamily: 'monospace' }}>
                {`GET https://openapi.ip.setonink.com/domain-whois?token=YOUR_TOKEN&domain=baidu.com`}
              </Box>
            </Paper>
          </Box>

          {/* 返回示例 */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              返回示例
            </Typography>
            <Paper elevation={3} sx={{ p: 2, whiteSpace: 'pre-wrap', backgroundColor: '#f9f9f9' }}>
              <Typography variant="body1">
                <strong>JSON:</strong>
              </Typography>
              <Box sx={{ mt: 2, fontFamily: 'monospace' }}>
                {`{
  "ret": "ok",  // 返回状态，"ok" 表示查询成功
  "result": {
    "domain": "BAIDU.COM",  // 查询的域名
    "registrar": "MarkMonitor Inc.",  // 注册商名称，负责管理该域名的公司
    "registrarURL": "http://www.markmonitor.com",  // 注册商的官方网站 URL
    "ianaId": "292",  // 注册商的 IANA 编号（Internet Assigned Numbers Authority）
    "whoisServer": "whois.markmonitor.com",  // Whois 查询的服务器地址
    "updatedDate": "2023-11-30T06:00:19.000Z",  // 域名信息最近一次更新的日期
    "creationDate": "1999-10-11T11:05:17.000Z",  // 域名的创建日期
    "expirationDate": "2026-10-11T11:05:17.000Z",  // 域名的到期日期
    "status": [
      {
        "status": "clientDeleteProhibited",  // 域名状态，表示该域名不能被删除
        "url": "https://icann.org/epp#clientDeleteProhibited"  // 与此状态相关的 ICANN 说明链接
      },
      {
        "status": "clientTransferProhibited",  // 域名状态，表示该域名不能被转移
        "url": "https://icann.org/epp#clientTransferProhibited"  // 与此状态相关的 ICANN 说明链接
      }
    ],
    "nameServers": [
      "NS1.BAIDU.COM",  // 域名的主名称服务器
      "NS2.BAIDU.COM"   // 域名的备份名称服务器
    ],
    "registrantOrganization": "Unknown",  // 注册人所属的组织（这里未提供确切信息）
    "registrantCountry": "Unknown",  // 注册人的国家（这里未提供确切信息）
    "registrantPhone": "+1 2086851750",  // 注册人的联系电话号码
    "registrantEmail": "abusecomplaints@markmonitor.com",  // 注册人的电子邮件地址
    "dnssec": "unsigned",  // DNSSEC 状态，"unsigned" 表示未使用 DNSSEC 进行安全保护
    "rawWhoisContent": "   Domain Name: BAIDU.COM..."  // Whois 查询返回的原始内容，包含完整的注册信息记录
  }
}`}
              </Box>
            </Paper>
          </Box>
        </>
      )}

      {/* ICP 备案查询接口文档 */}
      {selectedDoc === 'icp-query' && (
        <>
          <Typography variant="h4" gutterBottom>
            ICP备案查询接口文档
          </Typography>

          {/* 请求方式 */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              请求方式
            </Typography>
            <Paper elevation={3} sx={{ p: 2 }}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell>HTTP 方法</TableCell>
                    <TableCell>请求地址</TableCell>
                    <TableCell>是否需要认证</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>GET</TableCell>
                    <TableCell>
                      <a href="https://openapi.ip.setonink.com/domainicp?token=YOUR_TOKEN&domain=TARGET_DOMAIN" target="_blank" rel="noopener noreferrer">
                        https://openapi.ip.setonink.com/domainicp
                      </a><br />
                      <Typography variant="body2">
                        请求地址格式: https://openapi.ip.setonink.com/domainicp?token=YOUR_TOKEN&domain=TARGET_DOMAIN
                      </Typography>
                    </TableCell>
                    <TableCell>是</TableCell>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </Paper>
          </Box>

          {/* 请求参数 */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              请求参数
            </Typography>
            <Paper elevation={3} sx={{ p: 2 }}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell>参数名称</TableCell>
                    <TableCell>类型</TableCell>
                    <TableCell>是否必须</TableCell>
                    <TableCell>说明</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>domain</TableCell>
                    <TableCell>string</TableCell>
                    <TableCell>是</TableCell>
                    <TableCell>需要查询的域名。</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>token</TableCell>
                    <TableCell>string</TableCell>
                    <TableCell>是</TableCell>
                    <TableCell>用户的API调用凭证，直接添加到请求地址中的查询参数。</TableCell>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </Paper>
          </Box>

          {/* 请求示例 */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              请求示例
            </Typography>
            <Paper elevation={3} sx={{ p: 2, whiteSpace: 'pre-wrap', backgroundColor: '#f9f9f9' }}>
              <Typography variant="body1">
                <strong>ICP 查询请求示例:</strong>
              </Typography>
              <Box sx={{ mt: 2, fontFamily: 'monospace' }}>
                {`GET https://openapi.ip.setonink.com/domainicp?token=YOUR_TOKEN&domain=11dun.com`}
              </Box>
            </Paper>
          </Box>

          {/* 返回示例 */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              返回示例
            </Typography>
            <Paper elevation={3} sx={{ p: 2, whiteSpace: 'pre-wrap', backgroundColor: '#f9f9f9' }}>
              <Typography variant="body1">
                <strong>JSON:</strong>
              </Typography>
              <Box sx={{ mt: 2, fontFamily: 'monospace' }}>
                {`{
    "ret": "ok",
    "Result": {
        "CompanyName": "南京思顿软件科技有限公司",
        "CompanyType": "企业",
        "SiteLicense": "苏ICP备2022048063号-5",
        "SiteName": "壹盾安全",
        "MainPage": "www.11dun.com",
        "VerifyTime": "2023-12-06"
    }
}`}
              </Box>
            </Paper>
          </Box>
        </>
      )}
    </Container>
  );
};

export default DocPage;
