import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../services/api';
import {
    Container,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Pagination,
    CircularProgress,
    Alert,
    Stack,
    Button,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';

// 确保主题的颜色有默认值，避免 undefined 的读取问题
const defaultGrey = '#f5f5f5';
const defaultDivider = '#ddd';
const defaultHover = '#fafafa';

// 定义自定义样式的表格组件，添加默认值，防止样式未定义
const StyledTable = styled(Table)(({ theme }) => ({
    '& .MuiTableCell-head': {
        backgroundColor: theme?.palette?.grey?.[200] || defaultGrey, // 使用默认灰色
        fontWeight: 'bold',
    },
    '& .MuiTableCell-root': {
        padding: theme?.spacing(2) || '16px', // 使用默认间距
        borderBottom: `1px solid ${theme?.palette?.divider || defaultDivider}`, // 默认边框颜色
        whiteSpace: 'nowrap', // 避免换行，针对其他列
    },
    '& .MuiTableRow-root:last-child .MuiTableCell-root': {
        borderBottom: 'none',
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-of-type(odd)': {
        backgroundColor: theme?.palette?.action?.hover || defaultHover, // 使用默认hover背景色
    },
    '& .MuiTableCell-result': {
        whiteSpace: 'normal', // 结果列内容自动换行
        wordBreak: 'break-all', // 强制长单词或URL换行
    },
}));

const FreeIpDataLogs = () => {
    const [logs, setLogs] = useState([]); // 存储日志数据
    const [loading, setLoading] = useState(true); // 加载状态
    const [error, setError] = useState(''); // 错误信息
    const [page, setPage] = useState(1); // 当前页数
    const [totalPages, setTotalPages] = useState(1); // 总页数
    const [totalLogs, setTotalLogs] = useState(0); // 总日志数
    const [cleanupLoading, setCleanupLoading] = useState(false); // 删除按钮的加载状态

    // 获取日志数据
    const fetchLogs = async (page = 1) => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get(`${API_BASE_URL}/admin/free-ip-logs`, {
                params: { page, limit: 10 },
                headers: { Authorization: localStorage.getItem('token') },
            });

            if (response.status === 200 && response.data) {
                setLogs(response.data.data || []); // 确保数据为数组
                setTotalPages(response.data.totalPages || 1); // 确保总页数有默认值
                setTotalLogs(response.data.total || 0); // 从后端获取总日志数
            } else {
                setError('无法获取日志数据。');
            }
        } catch (err) {
            setError('获取日志数据失败，请重试');
        } finally {
            setLoading(false);
        }
    };

    // 清理1个月前的日志
    const handleCleanup = async () => {
        setCleanupLoading(true); // 设置加载状态
        try {
            const response = await axios.delete(`${API_BASE_URL}/admin/free-ip-logs/cleanup`, {
                headers: { Authorization: localStorage.getItem('token') },
            });

            if (response.status === 200) {
                alert(`成功删除 ${response.data.deletedCount} 条1个月前的日志`);
                fetchLogs(page); // 删除后刷新日志
            } else {
                alert('删除日志失败');
            }
        } catch (err) {
            console.error('删除日志失败:', err);
            alert('删除日志失败');
        } finally {
            setCleanupLoading(false); // 停止加载状态
        }
    };

    useEffect(() => {
        fetchLogs(page);
    }, [page]);

    return (
        <Container maxWidth="lg">
            <Box sx={{ mt: 5, mb: 5 }}>
                <Typography variant="h4" gutterBottom>
                    开放API调用日志
                </Typography>

                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Paper elevation={3} sx={{ p: 2 }}>
                        {/* 表格标题和删除按钮 */}
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ mb: 3, px: 2 }}
                        >
                            <Typography variant="h6">
                                日志总数：{totalLogs}
                            </Typography>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleCleanup}
                                disabled={cleanupLoading}
                            >
                                {cleanupLoading ? '删除中...' : '删除1个月前日志'}
                            </Button>
                        </Stack>

                        {/* 日志表格 */}
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell>查询 IP</TableCell>
                                    <TableCell className="MuiTableCell-result">结果</TableCell>
                                    <TableCell>成功状态</TableCell>
                                    <TableCell>时间</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {logs.length > 0 ? logs.map((log) => (
                                    <TableRow key={log._id}>
                                        <TableCell>{log.queriedIP || 'N/A'}</TableCell>
                                        <TableCell className="MuiTableCell-result">{log.result || '无结果'}</TableCell>
                                        <TableCell>{log.isSuccess ? '成功' : '失败'}</TableCell>
                                        <TableCell>
                                            {log.createdAt
                                                ? new Date(log.createdAt).toLocaleString('zh-CN', {
                                                      timeZone: 'Asia/Shanghai',
                                                  })
                                                : '未知时间'}
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            没有日志数据
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </StyledTable>

                        {/* 分页组件 */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                            <Pagination
                                count={totalPages}
                                page={page}
                                onChange={(e, value) => setPage(value)}
                                color="primary"
                                shape="rounded"
                                size="large"
                            />
                        </Box>
                    </Paper>
                )}
            </Box>
        </Container>
    );
};

export default FreeIpDataLogs;
