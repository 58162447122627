import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Form, Modal, Container, Row, Col, Pagination } from 'react-bootstrap';
import axios from 'axios';
import { API_BASE_URL } from '../services/api';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [newUser, setNewUser] = useState({ username: '', email: '', password: '', balance: 0 });
    const [editUser, setEditUser] = useState({ _id: '', username: '', email: '', balance: 0 });

    const fetchUsers = useCallback(async () => {
        try {
            const res = await axios.get(`${API_BASE_URL}/admin/users`, {
                params: { query: searchQuery, page: currentPage, limit: 10 },
                headers: { Authorization: localStorage.getItem('token') }
            });
            setUsers(res.data.users);
            setTotalPages(res.data.totalPages);
        } catch (error) {
            console.error('获取用户失败:', error);
        }
    }, [searchQuery, currentPage]);  // 只有当 searchQuery 或 currentPage 发生变化时才重新创建函数

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);  // 依赖于稳定的 fetchUsers 函数
    
    const handleSearch = async () => {
        setCurrentPage(1); // 重置到第一页
        fetchUsers();
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleCreateUser = async () => {
        try {
            await axios.post(`${API_BASE_URL}/admin/users`, newUser, {
                headers: { Authorization: localStorage.getItem('token') }
            });
            setShowCreateModal(false);
            fetchUsers();  // 创建成功后刷新列表
        } catch (error) {
            console.error('创建用户失败:', error);
        }
    };

    const handleDeleteUser = async (userId) => {
        if (window.confirm('确定要删除该用户吗？')) {
            try {
                await axios.delete(`${API_BASE_URL}/admin/users/${userId}`, {
                    headers: { Authorization: localStorage.getItem('token') }
                });
                fetchUsers();  // 删除成功后刷新用户列表
            } catch (error) {
                console.error('删除用户失败:', error);
                alert('删除用户失败');
            }
        }
    };

    const handleEditClick = (user) => {
        setEditUser({ ...user, password: '' }); // 设置要编辑的用户, 这里将密码字段设为空
        setShowEditModal(true); // 显示编辑Modal
    };

    const handleEditUser = async () => {
        try {
            await axios.put(`${API_BASE_URL}/admin/users/${editUser._id}`, editUser, {
                headers: { Authorization: localStorage.getItem('token') }
            });
            setShowEditModal(false); // 关闭编辑Modal
            fetchUsers();  // 修改成功后刷新列表
        } catch (error) {
            console.error('修改用户失败:', error);
        }
    };

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={12}>
                    <h1 className="text-center my-4">用户管理</h1>
                    <Form.Control
                        type="text"
                        placeholder="搜索用户 (用户名或邮箱)"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="my-3"
                    />
                    <div className="d-flex justify-content-between">
                        <Button variant="primary" onClick={handleSearch}>
                            搜索
                        </Button>
                        <Button variant="success" onClick={() => setShowCreateModal(true)}>
                            创建用户
                        </Button>
                    </div>

                    <Table striped bordered hover className="mt-3">
                        <thead>
                            <tr>
                                <th>用户名</th>
                                <th>邮箱</th>
                                <th>余额</th>
                                <th>角色</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user._id}>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>{user.balance}</td>
                                    <td>{user.role === 'admin' ? '管理员' : '普通用户'}</td> {/* 显示用户或管理员 */}
                                    <td>
                                        <Button
                                            variant="warning"
                                            className="me-2"
                                            onClick={() => handleEditClick(user)}
                                        >
                                            修改
                                        </Button>
                                        <Button variant="danger" onClick={() => handleDeleteUser(user._id)}>
                                            删除
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {/* 分页组件 */}
                    <Pagination>
                        {[...Array(totalPages)].map((_, index) => (
                            <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>

                    {/* 创建用户的 Modal */}
                    <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>创建新用户</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group controlId="formUsername">
                                    <Form.Label>用户名</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="输入用户名"
                                        value={newUser.username}
                                        onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>邮箱</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="输入邮箱"
                                        value={newUser.email}
                                        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formPassword">
                                    <Form.Label>密码</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="输入密码"
                                        value={newUser.password}
                                        onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBalance">
                                    <Form.Label>余额</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="输入余额"
                                        value={newUser.balance}
                                        onChange={(e) => setNewUser({ ...newUser, balance: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formRole">
                                    <Form.Label>角色</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={newUser.role}
                                        onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                                    >
                                        <option value="user">普通用户</option>
                                        <option value="admin">管理员</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
                                取消
                            </Button>
                            <Button variant="primary" onClick={handleCreateUser}>
                                创建
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* 编辑用户的 Modal */}
                    <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>修改用户信息</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group controlId="formUsernameEdit">
                                    <Form.Label>用户名</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="输入用户名"
                                        value={editUser.username}
                                        onChange={(e) => setEditUser({ ...editUser, username: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formEmailEdit">
                                    <Form.Label>邮箱</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="输入邮箱"
                                        value={editUser.email}
                                        onChange={(e) => setEditUser({ ...editUser, email: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBalanceEdit">
                                    <Form.Label>余额</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="输入余额"
                                        value={editUser.balance}
                                        onChange={(e) => setEditUser({ ...editUser, balance: e.target.value })}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formRoleEdit">
                                    <Form.Label>角色</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={editUser.role}
                                        onChange={(e) => setEditUser({ ...editUser, role: e.target.value })}
                                    >
                                        <option value="user">普通用户</option>
                                        <option value="admin">管理员</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="formPasswordEdit">
                                    <Form.Label>新密码</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="输入新密码 (可选)"
                                        value={editUser.password}
                                        onChange={(e) => setEditUser({ ...editUser, password: e.target.value })}
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                                取消
                            </Button>
                            <Button variant="primary" onClick={handleEditUser}>
                                保存
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>
            </Row>
        </Container>
    );
};

export default UserManagement;
