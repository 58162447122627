import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../services/api';
import { Link, useNavigate } from 'react-router-dom';
import {
    Container,
    TextField,
    Button,
    Typography,
    Box,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [countdown, setCountdown] = useState(0);
    const navigate = useNavigate();

    // 倒计时逻辑
    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [countdown]);

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const handleSendVerificationCode = async () => {
        if (countdown > 0) return;
        try {
            if (!recaptchaToken) {
                setDialogMessage('请先完成 reCAPTCHA 验证');
                setDialogOpen(true);
                return;
            }

            // 调用后端发送验证码
            const res = await axios.post(`${API_BASE_URL}/auth/send-verification-code`, {
                email,
                recaptchaToken,
            });

            if (res.data.success) {
                setEmailSent(true);
                setDialogMessage('验证码已发送，请检查您的邮箱。');
                setDialogOpen(true);
                setCountdown(60);
            } else {
                setDialogMessage('验证码发送失败，请重试。');
                setDialogOpen(true);
            }
        } catch (err) {
            setDialogMessage('验证码发送失败，请检查您的邮箱是否正确。');
            setDialogOpen(true);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!emailSent) {
          setDialogMessage('请先发送验证码并进行邮箱验证。');
          setDialogOpen(true);
          return;
        }
      
        try {
          // 验证邮箱验证码
          const verifyRes = await axios.post(`${API_BASE_URL}/auth/verify-email-code`, {
            email,
            verificationCode,
          });
      
          const result = verifyRes.data;  // 从 verifyRes.data 中获取返回的对象
      
          // 检查返回的结果
          if (result.success) {
            // 验证成功，进行注册
            const res = await axios.post(`${API_BASE_URL}/auth/register`, {
              username,
              email,
              password,
            });
      
            if (res.data) {
              setDialogMessage('注册成功，点击确认跳转到登录页。');
              setDialogOpen(true);
            }
          } else {
            // 验证失败，显示错误消息
            setDialogMessage(result.message || '验证码错误或已过期，请重新获取。');
            setDialogOpen(true);
          }
        } catch (err) {
          console.error('注册失败:', err.response ? err.response.data : err.message);
          setDialogMessage(err.response?.data?.message || '注册失败，请稍后再试。');
          setDialogOpen(true);
        }
      };
      
    const handleDialogClose = () => {
        setDialogOpen(false);

        // 如果是注册成功的消息，关闭弹窗后跳转到登录页
        if (dialogMessage === '注册成功，点击确认跳转到登录页。') {
            navigate('/login');
        }
    };

    return (
        <Container maxWidth="sm" style={{ marginTop: '10vh' }}>
            <Box
                sx={{
                    padding: 5,
                    backgroundColor: '#f4f6f8',
                    borderRadius: 3,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography variant="h4" align="center" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
                    创建您的账号
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                    快速注册成为壹盾综合数据平台用户
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="用户名"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        InputLabelProps={{ style: { color: '#3f51b5' } }}
                        sx={{ backgroundColor: '#fff', borderRadius: 2 }}
                    />
                    <TextField
                        label="邮箱"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        InputLabelProps={{ style: { color: '#3f51b5' } }}
                        sx={{ backgroundColor: '#fff', borderRadius: 2 }}
                    />

                    {/* reCAPTCHA 验证，强制使用大陆可访问的链接 */}
                    <Box display="flex" justifyContent="center" marginTop={2} marginBottom={3}>
                        <ReCAPTCHA
                            sitekey="6LeLbw4qAAAAAIPFN7f3NrPVosRGLlDtt3gkmHFn"
                            onChange={handleRecaptchaChange}
                            hl="zh-CN"
                            theme="light"
                            size="normal"
                            scriptProps={{ src: 'https://recaptcha.net/recaptcha/api.js' }}
                        />
                    </Box>

                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        sx={{ paddingY: 1.5, fontWeight: 'bold', backgroundColor: '#673ab7', marginBottom: 2 }}
                        onClick={handleSendVerificationCode}
                        disabled={countdown > 0}
                    >
                        {countdown > 0 ? `重新发送 (${countdown})` : '发送验证码'}
                    </Button>

                    <TextField
                        label="输入邮箱验证码"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        required
                        InputLabelProps={{ style: { color: '#3f51b5' } }}
                        sx={{ backgroundColor: '#fff', borderRadius: 2 }}
                    />

                    <TextField
                        label="密码"
                        variant="outlined"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        InputLabelProps={{ style: { color: '#3f51b5' } }}
                        sx={{ backgroundColor: '#fff', borderRadius: 2 }}
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ paddingY: 1.5, fontWeight: 'bold', marginTop: 2 }}
                    >
                        注册
                    </Button>
                </form>
                <Grid container justifyContent="center" sx={{ marginTop: 3 }}>
                    <Grid item>
                        <Typography variant="body2">
                            已有账号？ <Link to="/login" style={{ color: '#673ab7', textDecoration: 'none', fontWeight: 'bold' }}>点击登录</Link>
                        </Typography>
                    </Grid>
                </Grid>

                {/* 弹窗组件 */}
                <Dialog open={dialogOpen} onClose={handleDialogClose}>
                    <DialogTitle>提示</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {dialogMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            确认
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Container>
    );
};

export default Register;
