import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Button, Grid, Alert, Paper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../services/api';

const PurchasePlans = () => {
  const [plans, setPlans] = useState([]);
  const [balance, setBalance] = useState(0); // 用户余额
  const [purchaseSuccess, setPurchaseSuccess] = useState(false); // 购买是否成功
  const [errorMessage, setErrorMessage] = useState(''); // 错误信息
  const [token, setToken] = useState(''); // 购买成功后返回的调用 token
  const [openDialog, setOpenDialog] = useState(false); // 控制确认购买弹窗状态
  const [selectedPlan, setSelectedPlan] = useState(null); // 被选中的套餐

  const username = localStorage.getItem('username'); // 从localStorage获取用户名

  useEffect(() => {
    // 获取套餐信息
    const fetchPlans = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${API_BASE_URL}/plans/plans`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPlans(response.data.plans);
      } catch (err) {
        console.error('获取套餐信息失败:', err);
      }
    };

    // 获取用户余额
    const fetchBalance = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${API_BASE_URL}/auth/balance`, {
          params: { username },
          headers: { Authorization: `Bearer ${token}` },
        });
        setBalance(response.data.balance);
      } catch (err) {
        console.error('获取用户余额失败:', err);
      }
    };

    fetchPlans();
    fetchBalance();
  }, [username]);

  // 打开确认购买弹窗
  const handleOpenDialog = (plan) => {
    setSelectedPlan(plan); // 保存当前选择的套餐
    setOpenDialog(true); // 打开弹窗
  };

  // 关闭确认购买弹窗
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPlan(null); // 清除选中的套餐
  };

  // 购买套餐
  const purchasePlan = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        `${API_BASE_URL}/plans/purchase-plan`,
        { planId: selectedPlan.id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setPurchaseSuccess(true);
      setToken(response.data.token); // 成功后返回的调用 token
      setBalance(response.data.newBalance); // 更新余额
      setErrorMessage('');
      handleCloseDialog(); // 关闭弹窗
    } catch (err) {
      setErrorMessage(err.response.data.error);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Box sx={{ textAlign: 'center', mb: 5 }}>
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          购买套餐
        </Typography>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          当前余额: <strong>{balance}</strong> 元
        </Typography>
      </Box>

      {purchaseSuccess && (
        <Alert severity="success" sx={{ mb: 2 }}>
          套餐购买成功！您的调用 Token: <strong>{token}</strong>
        </Alert>
      )}

      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}

      <Grid container spacing={3}>
        {plans.map((plan) => (
          <Grid item xs={12} sm={6} md={3} key={plan.id}>
            <Paper
              elevation={8}
              sx={{
                p: 3, // 增加内边距，拉宽产品框
                backgroundColor: '#fff',
                borderRadius: '12px',
                transition: 'transform 0.2s ease-in-out',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.15)',
                },
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  gutterBottom
                  sx={{ color: '#34495e', mb: 1 }}
                >
                  {plan.name}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ color: '#3498db', mb: 1 }}
                >
                  价格: ¥{plan.price}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  调用次数: {plan.calls}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  有效期: {plan.validity} 年
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    px: 4,
                    py: 1,
                    fontWeight: 'bold',
                    borderRadius: '8px',
                    backgroundColor: '#3498db',
                    '&:hover': {
                      backgroundColor: '#2980b9',
                    },
                  }}
                  onClick={() => handleOpenDialog(plan)} // 打开确认购买弹窗
                >
                  购买
                </Button>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* 购买确认弹窗 */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>确认购买</DialogTitle>
        <DialogContent>
          <DialogContentText>
            您确定要购买 <strong>{selectedPlan?.name}</strong> 套餐吗？价格为 ¥{selectedPlan?.price}，该套餐包含 {selectedPlan?.calls} 次调用次数，有效期为 {selectedPlan?.validity} 年。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>取消</Button>
          <Button onClick={purchasePlan} variant="contained" color="primary">
            确认购买
          </Button>
        </DialogActions>
      </Dialog>

      {/* 购买提示信息 */}
      <Box
        sx={{
          mt: 5,
          textAlign: 'center',
          p: 3,
          borderRadius: '8px',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Typography variant="body2" color="text.secondary">
          请注意：所有套餐均为虚拟产品，购买后立即生效。因虚拟产品的特殊性，我们不支持三天无理由退款。
          如有任何问题，请联系客服处理。
        </Typography>
      </Box>
    </Container>
  );
};

export default PurchasePlans;
