import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Avatar, Button, Grid, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../services/api';

const UserDashboard = () => {
  const [balance, setBalance] = useState(null); // 存储余额
  const [loginInfo, setLoginInfo] = useState(null); // 存储登录信息（IP，时间）
  const [ipLocation, setIpLocation] = useState(''); // 存储IP属地
  const [loading, setLoading] = useState(true); // 加载状态
  const [partners, setPartners] = useState([]); // 存储合作伙伴信息

  const username = localStorage.getItem('username'); // 从localStorage获取用户名
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token'); // 获取 token

        // 获取用户余额信息，通过用户名从数据库查询
        const balanceRes = await axios.get(`${API_BASE_URL}/auth/balance`, {
          params: { username }, // 通过用户名查询余额
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBalance(balanceRes.data.balance); // 假设返回的数据中包含余额

        // 获取登录 IP 和时间
        const loginRes = await axios.get(`${API_BASE_URL}/auth/login-info`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLoginInfo(loginRes.data);

        // 获取 IP 属地信息
        if (loginRes.data.loginIP) {
          const ipRes = await axios.get(`${API_BASE_URL}/auth/ip-location`, {
            params: { ip: loginRes.data.loginIP }, // 向后端传递 IP 参数
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          // 组合 country.name 和 regions 作为 IP 属地
          const location = ipRes.data.country.name + ipRes.data.regions.join('');
          setIpLocation(location);
        }

        // 获取合作伙伴信息
        const partnersRes = await axios.get(`${API_BASE_URL}/partners`);
        setPartners(partnersRes.data); // 假设后端返回的数据为合作伙伴的数组

        setLoading(false); // 加载结束
      } catch (err) {
        console.error('获取用户信息失败:', err);
        setLoading(false); // 即使失败，也要结束加载状态
      }
    };

    fetchUserData();
  }, [username]); // 在用户名发生变化时重新获取数据

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ backgroundColor: '#f0f2f5', minHeight: '100vh', paddingY: 4 }}>
      <Container maxWidth="lg">
        <Box display="flex" flexDirection="column" alignItems="center" sx={{ mb: 5 }}>
          {/* 使用卡通头像 */}
          <Avatar
            src={`https://api.dicebear.com/9.x/pixel-art/svg`}
            alt="用户头像"
            sx={{ width: 120, height: 120, mb: 3 }}
          />

          {/* 欢迎信息 */}
          <Typography variant="h4" gutterBottom>
            尊敬的用户 {username}，欢迎使用壹盾综合数据平台
          </Typography>
        </Box>

        {/* 用户账户和登录信息 */}
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                backgroundColor: '#fff',
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                padding: 3,
                textAlign: 'center',
              }}
            >
              <Typography variant="h6" gutterBottom>
                账户信息
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                用户名: {username}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                账户余额: {balance} 元
              </Typography>
              <Button variant="contained" color="success" sx={{ px: 4 }} onClick={() => navigate('/recharge')}>
                充值
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={5}>
            <Box
              sx={{
                backgroundColor: '#fff',
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                padding: 3,
                textAlign: 'center',
              }}
            >
              <Typography variant="h6" gutterBottom>
                登录信息
              </Typography>
              <Typography variant="body1" sx={{ mb: 2.25 }}>
                登录时间: {new Date(loginInfo?.loginTime).toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' })}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2.25 }}>
                登录IP: {loginInfo?.loginIP}
              </Typography>
              <Typography variant="body1">
                IP属地: {ipLocation}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* 推荐服务 */}
        <Box sx={{ mt: 5 }}>
          <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', mb: 3 }}>
            推荐服务
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  padding: 3,
                  textAlign: 'center',
                }}
              >
                <Typography variant="h6" gutterBottom>
                  IP查询
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  通过IP查询相关地理位置、服务商等详细信息。
                </Typography>
                <Button variant="contained" color="primary" onClick={() => navigate('/plans')}>
                  立即申请
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  padding: 3,
                  textAlign: 'center',
                }}
              >
                <Typography variant="h6" gutterBottom>
                  手机号归属地
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  查询手机号归属的省份、城市以及运营商。
                </Typography>
                <Button variant="contained" color="primary" disabled>
                  暂未上线
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  padding: 3,
                  textAlign: 'center',
                }}
              >
                <Typography variant="h6" gutterBottom>
                  天气查询
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  通过IP查询当地的实时天气信息。
                </Typography>
                <Button variant="contained" color="primary" disabled>
                  暂未上线
                </Button>
              </Box>
            </Grid>

            {/* 新增推荐服务 */}
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  padding: 3,
                  textAlign: 'center',
                }}
              >
                <Typography variant="h6" gutterBottom>
                  ICP备案查询
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  查询网站的ICP备案信息，确保合规。
                </Typography>
                <Button variant="contained" color="primary" onClick={() => navigate('/plans')}>
                  立即申请
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  padding: 3,
                  textAlign: 'center',
                }}
              >
                <Typography variant="h6" gutterBottom>
                  域名WHOIS信息
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  查询域名的所有者、注册信息等详细信息。
                </Typography>
                <Button variant="contained" color="primary" onClick={() => navigate('/plans')}>
                  立即申请
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  padding: 3,
                  textAlign: 'center',
                }}
              >
                <Typography variant="h6" gutterBottom>
                  网站SEO分析
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  分析网站的SEO信息，提高搜索引擎排名。
                </Typography>
                <Button variant="contained" color="primary" disabled>
                  暂未上线
                </Button>
              </Box>
              
            </Grid>
          </Grid>
        </Box>

        {/* 合作伙伴 Logo 部分 */}
        <Box sx={{ mt: 8 }}>
          <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', mb: 3 }}>
            他们在用我们的数据
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {partners.map((partner, index) => (
              <Grid item xs={12} sm={4} md={2.4} key={index}>
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: 2,
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 120, // 调整高度
                    padding: 2, // 给logo一些内边距
                  }}
                >
                  <img
                    src={partner.logoUrl} // 后端返回的logo URL
                    alt={partner.name}
                    style={{ maxWidth: '100%', maxHeight: '100%' }} // 自动调整大小以适应框
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default UserDashboard;
