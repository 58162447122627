import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, Button, Box, Link, Container, Modal, Backdrop } from '@mui/material';
import { Security, Place, Public } from '@mui/icons-material';
import axios from 'axios';
import { API_BASE_URL } from '../services/api';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const Login = ({ setRole, setIsLoggedIn }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [open, setOpen] = useState(false);
    const [versionInfo, setVersionInfo] = useState({ version: '', log: '' });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    // 在组件加载时自动获取版本号和更新日志
    useEffect(() => {
        const fetchVersionInfo = async () => {
            try {
                const res = await axios.get(`${API_BASE_URL}/update`);
                setVersionInfo(res.data);
            } catch (error) {
                console.error('获取版本信息失败:', error);
            }
        };

        fetchVersionInfo();
    }, []);

    // 打开弹窗并显示版本号和日志
    const handleOpen = () => {
        setOpen(true);
    };

    // 关闭弹窗
    const handleClose = () => {
        setOpen(false);
    };

    // 登录表单提交
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const res = await axios.post(`${API_BASE_URL}/auth/login`, {
                username,
                password,
            });

            const { token, role, username: fetchedUsername } = res.data;  // 获取username

            // 保存 token 和 username 到 localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('username', fetchedUsername);  // 保存获取到的username
            localStorage.setItem('role', role);

            // 通过 props 更新 App 组件中的状态
            setRole(role);
            setIsLoggedIn(true);

            // 根据角色跳转
            if (role === 'admin') {
                navigate('/admin');  // 如果是管理员，跳转到管理员后台
            } else {
                navigate('/user');  // 普通用户，跳转到用户首页
            }
        } catch (error) {
            setError('登录失败，请检查您的用户名和密码。');
            console.error('登录失败:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <Container maxWidth="lg" style={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
            <Grid container spacing={6}>
                {/* 左侧信息部分 */}
                <Grid item xs={12} md={8}>
                    <Box>
                        <Typography variant="h3" component="h1" gutterBottom>
                            壹盾综合数据平台
                        </Typography>

                        <Box display="flex" alignItems="center" mb={2}>
                            <Security fontSize="large" color="primary" style={{ marginRight: 10 }} />
                            <Typography variant="h6" gutterBottom>
                                提供丰富的API接口服务
                            </Typography>
                        </Box>
                        <Typography paragraph style={{ lineHeight: '1.5' }}>
                            壹盾综合数据平台为您提供多种API查询服务，包括IP地址查询、域名Whois查询、ICP备案查询等，帮助您提升网络数据的安全性和便捷性。
                        </Typography>

                        <Box display="flex" alignItems="center" mb={2}>
                            <Place fontSize="large" color="primary" style={{ marginRight: 10 }} />
                            <Typography variant="h6" gutterBottom>
                                IP地址查询
                            </Typography>
                        </Box>
                        <Typography paragraph style={{ lineHeight: '1.5' }}>
                            我们的IP地址查询服务帮助您快速获取IP属地信息、自治系统编号和运营商信息等，广泛应用于网络安全防护和地理位置分析。
                        </Typography>

                        <Box display="flex" alignItems="center" mb={2}>
                            <Public fontSize="large" color="primary" style={{ marginRight: 10 }} />
                            <Typography variant="h6" gutterBottom>
                                域名Whois查询
                            </Typography>
                        </Box>
                        <Typography paragraph style={{ lineHeight: '1.5' }}>
                            您可以通过平台快速查询域名注册信息、注册商信息、到期日期等详细Whois数据，帮助您追踪和分析域名状态。
                        </Typography>

                        <Box display="flex" alignItems="center" mb={2}>
                            <Public fontSize="large" color="primary" style={{ marginRight: 10 }} />
                            <Typography variant="h6" gutterBottom>
                                ICP备案查询
                            </Typography>
                        </Box>
                        <Typography paragraph style={{ lineHeight: '1.5' }}>
                            我们提供ICP备案查询服务，帮助您了解网站备案状态，确保您的网站符合国家法规要求。
                        </Typography>
                    </Box>
                </Grid>

                {/* 右侧登录表单部分 */}
                <Grid item xs={12} md={4}>
                    <Box
                        sx={{
                            padding: 5,
                            backgroundColor: '#fff',
                            boxShadow: 3,
                            borderRadius: 2,
                            maxWidth: '500px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: '50px',
                        }}
                    >
                        <Typography variant="h4" align="center" gutterBottom style={{ fontSize: '1.8rem', whiteSpace: 'nowrap' }}>
                            壹盾综合数据平台登录
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                label="用户名"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                            <TextField
                                label="密码"
                                variant="outlined"
                                type="password"
                                fullWidth
                                margin="normal"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            {error && <Typography color="error" align="center" sx={{ marginTop: 2 }}>{error}</Typography>}
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{ marginY: 2 }}
                            >
                                登录
                            </Button>
                        </form>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="body2" component="span">
                                    没有账号？
                                </Typography>
                                <Link component={RouterLink} to="/register" variant="body2">
                                    注册
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link component={RouterLink} to="#" variant="body2">
                                    忘记密码？
                                </Link>
                            </Grid>
                        </Grid>
                        <Typography
                            variant="body2"
                            align="center"
                            sx={{ marginTop: 2, cursor: 'pointer' }}
                            onClick={handleOpen}
                        >
                            系统版本号: {versionInfo.version} (点击查看更新内容)
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 300,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        outline: 'none',
                    }}
                >
                    <Typography variant="h6" component="h2">
                        更新日志 (版本号: {versionInfo.version})
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        {versionInfo.log.split('\n').map((line, index) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </Typography>
                    <Button onClick={handleClose} sx={{ marginTop: 2 }} fullWidth variant="contained">
                        关闭
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
};

export default Login;