import React, { useEffect, useState, useCallback } from 'react';
import { Container, Typography, Box, Grid, CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, Pagination, Stack, Button, IconButton, Collapse } from '@mui/material';
import { API_BASE_URL } from '../services/api';
import axios from 'axios';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const AdminDashboard = () => {
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalPackages: 0,
    totalRevenue: 0,
    todayUsers: 0,
    todayPackages: 0,
    todayRevenue: 0,
  });

  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [cleanupLoading, setCleanupLoading] = useState(false); // 删除按钮的加载状态
  const [expandedRows, setExpandedRows] = useState({}); // 存储展开行状态

  const fetchDashboardData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/admin/dashboard-stats`, {
        headers: { Authorization: token },
      });
      setStats(response.data);
      setLoading(false);
    } catch (err) {
      console.error('获取统计数据失败:', err);
      setLoading(false);
    }
  }, []);

  const fetchLogs = useCallback(async (page = 1) => {
    setLoading(true);
    setError('');
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}/admin/call-logs`, {
        params: { page, limit: 10 },
        headers: { Authorization: token },
      });

      if (response.status === 200 && response.data) {
        setLogs(response.data.data || []);
        setTotalPages(response.data.totalPages || 1);
      } else {
        setError('无法获取日志数据。');
      }
    } catch (err) {
      setError('获取日志数据失败，请重试');
    } finally {
      setLoading(false);
    }
  }, []);

  // 删除1个月前的调用日志
  const handleCleanup = async () => {
    setCleanupLoading(true); // 开始加载状态
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${API_BASE_URL}/admin/call-logs/cleanup`, {
        headers: { Authorization: token },
      });
      if (response.status === 200) {
        alert(`成功删除 ${response.data.deletedCount} 条1个月前的日志`);
        fetchLogs(page); // 删除后重新获取日志数据
      } else {
        alert('删除日志失败');
      }
    } catch (err) {
      console.error('删除日志失败:', err);
      alert('删除日志失败');
    } finally {
      setCleanupLoading(false); // 停止加载状态
    }
  };

  useEffect(() => {
    fetchDashboardData();
    fetchLogs(page);
  }, [fetchDashboardData, fetchLogs, page]);

  // 格式化对象数据（可折叠显示）
  const formatLogResult = (result) => {
    return (
      <div>
        <div>IP: {result.ip}</div>
        <div>AS: {result.as?.name} ({result.as?.number})</div>
        <div>地址: {result.addr}</div>
        <div>国家: {result.country?.name} ({result.country?.code})</div>
        <div>注册国家: {result.registered_country?.name} ({result.registered_country?.code})</div>
        <div>区域: {result.regions?.join(', ')}</div>
        <div>类型: {result.type}</div>
      </div>
    );
  };

  // 切换行展开状态
  const toggleRowExpand = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom align="center">管理员仪表盘</Typography>

      <Grid container spacing={3}>
        {/* 总用户量 */}
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6">总用户量</Typography>
            <Typography variant="h4">{stats.totalUsers}</Typography>
          </Paper>
        </Grid>

        {/* 总套餐数 */}
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6">总套餐数</Typography>
            <Typography variant="h4">{stats.totalPackages}</Typography>
          </Paper>
        </Grid>

        {/* 总收入 */}
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6">总收入</Typography>
            <Typography variant="h4">¥{stats.totalRevenue}</Typography>
          </Paper>
        </Grid>

        {/* 今日注册用户数 */}
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6">今日注册用户数</Typography>
            <Typography variant="h4">{stats.todayUsers}</Typography>
          </Paper>
        </Grid>

        {/* 今日套餐数 */}
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6">今日套餐数</Typography>
            <Typography variant="h4">{stats.todayPackages}</Typography>
          </Paper>
        </Grid>

        {/* 今日收入 */}
        <Grid item xs={12} sm={6} md={4}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6">今日收入</Typography>
            <Typography variant="h4">¥{stats.todayRevenue}</Typography>
          </Paper>
        </Grid>

        {/* 调用日志表格 */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>调用日志</Typography>
              <Button
                variant="contained"
                color="error"
                onClick={handleCleanup}
                disabled={cleanupLoading}
              >
                {cleanupLoading ? '删除中...' : '删除1个月前日志'}
              </Button>
            </Stack>

            {error && <Typography color="error">{error}</Typography>}

            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>用户名</TableCell>
                      <TableCell>套餐名称</TableCell>
                      <TableCell>查询 IP</TableCell>
                      <TableCell>结果</TableCell>
                      <TableCell>成功状态</TableCell>
                      <TableCell>时间</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logs.length > 0 ? logs.map((log) => (
                      <React.Fragment key={log.log._id}>
                        <TableRow>
                          <TableCell>{log.username || '未知用户'}</TableCell>
                          <TableCell>{log.package?.planName || '未知套餐'}</TableCell>
                          <TableCell>{log.log.queriedIP || 'N/A'}</TableCell>
                          <TableCell>
                            <IconButton size="small" onClick={() => toggleRowExpand(log.log._id)}>
                              {expandedRows[log.log._id] ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                          </TableCell>
                          <TableCell>{log.log.isSuccess ? '成功' : '失败'}</TableCell>
                          <TableCell>{new Date(log.log.createdAt).toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' })}</TableCell>
                        </TableRow>
                        {/* 折叠部分显示详细结果 */}
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Collapse in={expandedRows[log.log._id]} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                {formatLogResult(log.log.result)}
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">没有日志数据</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

                {/* 分页 */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(e, value) => setPage(value)}
                    color="primary"
                    shape="rounded"
                    size="large"
                  />
                </Box>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;
