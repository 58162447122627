import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    CircularProgress,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Pagination,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { API_BASE_URL } from '../services/api'; // 替换为你的API地址

const ReviewCorrections = () => {
    const [corrections, setCorrections] = useState([]); // 存储纠错信息
    const [loading, setLoading] = useState(true); // 加载状态
    const [error, setError] = useState(''); // 错误信息
    const [page, setPage] = useState(1); // 当前页
    const [totalPages, setTotalPages] = useState(1); // 总页数
    const [selectedCorrection, setSelectedCorrection] = useState(null); // 当前选中的纠错信息
    const [reviewDialogOpen, setReviewDialogOpen] = useState(false); // 审核弹窗状态
    const [statusFilter, setStatusFilter] = useState(''); // 状态筛选
    const [status, setStatus] = useState(''); // 审核状态
    const [adminNote, setAdminNote] = useState(''); // 管理员备注
    const [actualUpdate, setActualUpdate] = useState(''); // 实际更新信息

    useEffect(() => {
        fetchCorrections(page, statusFilter);
    }, [page, statusFilter]);

    // 获取纠错信息，按最新提交顺序排列
    const fetchCorrections = async (page, statusFilter) => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get(`${API_BASE_URL}/ipCorrectionAdmin/corrections`, {
                params: { page, limit: 10, status: statusFilter }, // 添加 status 参数
                headers: { Authorization: localStorage.getItem('token') },
            });
            setCorrections(response.data.data);
            setTotalPages(response.data.totalPages);
        } catch (err) {
            setError('获取纠错信息失败，请重试');
        } finally {
            setLoading(false);
        }
    };

    // 打开审核弹窗
    const handleOpenReviewDialog = (correction) => {
        setSelectedCorrection(correction);
        setStatus(correction.status);
        setAdminNote('');
        setActualUpdate(''); // 默认将实际更新信息清空
        setReviewDialogOpen(true);
    };

    // 关闭审核弹窗
    const handleCloseReviewDialog = () => {
        setReviewDialogOpen(false);
    };

    // 提交审核
    const handleSubmitReview = async () => {
        if (!['已审核', '拒绝', '待审核'].includes(status)) {
            setError('请选择有效的审核状态');
            return;
        }

        // 如果状态是拒绝，设置 actualUpdate 为 "未更新"
        const actualUpdateToSend = status === '拒绝' ? '未更新' : actualUpdate;

        try {
            await axios.put(`${API_BASE_URL}/ipCorrectionAdmin/review-correction/${selectedCorrection._id}`, {
                status,
                adminNote,
                actualUpdate: actualUpdateToSend,
            }, {
                headers: { Authorization: localStorage.getItem('token') },
            });
            setReviewDialogOpen(false);
            fetchCorrections(page, statusFilter); // 重新获取更新后的数据
        } catch (err) {
            setError('处理纠错信息失败，请重试');
        }
    };

    // 删除纠错信息
    const handleDeleteCorrection = async (correctionId) => {
        try {
            await axios.delete(`${API_BASE_URL}/ipCorrectionAdmin/correction/${correctionId}`, {
                headers: { Authorization: localStorage.getItem('token') },
            });
            fetchCorrections(page, statusFilter); // 删除后重新获取数据
        } catch (err) {
            setError('删除纠错信息失败，请重试');
        }
    };

    // 处理分页切换
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <Container maxWidth="lg">
            <Box sx={{ mt: 5, mb: 5 }}>
                <Typography variant="h4" gutterBottom>
                    管理员审核纠错信息
                </Typography>

                {/* 状态筛选下拉框 */}
                <FormControl sx={{ minWidth: 200, mb: 3 }}>
                    <InputLabel id="status-filter-label">按状态筛选</InputLabel>
                    <Select
                        labelId="status-filter-label"
                        id="status-filter"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                        label="按状态筛选"
                    >
                        <MenuItem value="">全部</MenuItem>
                        <MenuItem value="已审核">已审核</MenuItem>
                        <MenuItem value="拒绝">拒绝</MenuItem>
                        <MenuItem value="待审核">待审核</MenuItem>
                    </Select>
                </FormControl>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                {loading ? (
                    <CircularProgress />
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>IP 段</TableCell>
                                    <TableCell>用户提交地址</TableCell>
                                    <TableCell>实际更新地址</TableCell>
                                    <TableCell>提交时间</TableCell>
                                    <TableCell>状态</TableCell>
                                    <TableCell>反馈信息</TableCell>
                                    <TableCell>管理员备注</TableCell> {/* 添加管理员备注表头 */}
                                    <TableCell>操作</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {corrections.map((correction) => (
                                    <TableRow key={correction._id}>
                                        <TableCell>{correction.startIP} - {correction.endIP || correction.startIP}</TableCell>
                                        <TableCell>{correction.location || '未知'}</TableCell> {/* 用户提交地址 */}
                                        <TableCell>{correction.actualUpdate || '未更新'}</TableCell> {/* 实际更新地址 */}
                                        <TableCell>{new Date(correction.createdAt).toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' })}</TableCell>
                                        <TableCell>{correction.status}</TableCell>
                                        <TableCell>{correction.feedback || '无'}</TableCell>
                                        <TableCell>{correction.adminNote || '无备注'}</TableCell> {/* 显示管理员备注 */}
                                        <TableCell>
                                            <IconButton color="primary" onClick={() => handleOpenReviewDialog(correction)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton color="secondary" onClick={() => handleDeleteCorrection(correction._id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {/* 分页组件 */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
                </Box>

                {/* 审核弹窗 */}
                <Dialog open={reviewDialogOpen} onClose={handleCloseReviewDialog}>
                    <DialogTitle>审核纠错信息</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6" gutterBottom>
                            IP: {selectedCorrection?.startIP} - {selectedCorrection?.endIP || selectedCorrection?.startIP}
                        </Typography>

                        <TextField
                            label="实际更新信息"
                            fullWidth
                            value={actualUpdate}
                            onChange={(e) => setActualUpdate(e.target.value)}
                            variant="outlined"
                            sx={{ mb: 2 }}
                            disabled={status === '拒绝'} // 如果状态是拒绝，禁用此字段
                        />

                        <TextField
                            label="管理员备注"
                            fullWidth
                            value={adminNote}
                            onChange={(e) => setAdminNote(e.target.value)}
                            variant="outlined"
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            label="审核状态"
                            fullWidth
                            select
                            SelectProps={{
                                native: true,
                            }}
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            variant="outlined"
                            sx={{ mb: 2 }}
                        >
                            <option value="">请选择状态</option>
                            <option value="已审核">已审核</option>
                            <option value="拒绝">拒绝</option>
                        </TextField>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleCloseReviewDialog} color="secondary">
                            取消
                        </Button>
                        <Button onClick={handleSubmitReview} color="primary">
                            提交
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Container>
    );
};

export default ReviewCorrections;
