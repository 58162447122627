import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Table, TableBody, TableCell, TableHead, TableRow, Paper, Button, Pagination, CircularProgress, Collapse, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import axios from 'axios';
import { API_BASE_URL } from '../services/api';

// UTC时间转为北京时间
const convertToBeijingTime = (utcTime) => {
    if (!utcTime) return '无时间信息';
    const date = new Date(utcTime);
  
    // 获取 UTC 时间戳并加上8小时的偏移量 (8 * 60 * 60 * 1000 毫秒)
    const beijingTime = new Date(date.getTime() + 8 * 60 * 60 * 1000);
  
    // 格式化为 yyyy-MM-dd HH:mm:ss 的北京时间格式
    return beijingTime.toLocaleString('zh-CN', { hour12: false });
};

// 解析域名状态并转换为中文
const parseDomainStatus = (statusArray) => {
    if (!Array.isArray(statusArray)) {
      return '无状态信息';
    }
  
    const statusMap = {
      "ok": "正常 (ok)",
      "clientDeleteProhibited": "客户端禁止删除 (clientDeleteProhibited)",
      "clientTransferProhibited": "客户端禁止转移 (clientTransferProhibited)",
      "clientUpdateProhibited": "客户端禁止更新 (clientUpdateProhibited)",
      "serverDeleteProhibited": "服务器禁止删除 (serverDeleteProhibited)",
      "serverTransferProhibited": "服务器禁止转移 (serverTransferProhibited)",
      "serverUpdateProhibited": "服务器禁止更新 (serverUpdateProhibited)",
    };
  
    return statusArray.map((statusObj) => {
      const statusKey = statusObj.status;
      return statusMap[statusKey] || `未知状态 (${statusKey})`;
    }).join(', ');
};

// 解析DNSSEC状态的中文说明
const parseDnssecStatus = (dnssec) => {
    if (!dnssec) return '未知状态';
    
    switch (dnssec.toLowerCase()) {
      case 'unsigned':
        return '未签名 (unsigned)';
      case 'signed':
        return '已签名 (signed)';
      default:
        return `未知状态 (${dnssec})`;
    }
};

const WhoisLogPage = () => {
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [deleting, setDeleting] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]); // 用于跟踪展开的行

  // 获取whois日志数据
  const fetchWhoisLogs = async (pageNumber) => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/whois-logs`, {
        params: { page: pageNumber, limit: 10 }, // 每页显示10条日志
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
        },
      });
      setLogs(response.data.data);
      setTotalPages(response.data.totalPages);
      setPage(response.data.currentPage);
      setLoading(false);
    } catch (err) {
      setError('无法获取Whois日志');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWhoisLogs(page);
  }, [page]);

  // 删除一个月前的日志
  const handleDeleteOldLogs = async () => {
    if (!window.confirm('您确定要删除一个月前的所有日志吗？')) return;

    setDeleting(true);
    try {
      await axios.delete(`${API_BASE_URL}/admin/whois-logs/cleanup`, {
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
        },
      });
      alert('一个月前的日志已删除');
      fetchWhoisLogs(page);
      setDeleting(false);
    } catch (err) {
      setError('删除日志失败');
      setDeleting(false);
    }
  };

  // 切换折叠状态
  const toggleRowExpansion = (index) => {
    setExpandedRows((prevState) =>
      prevState.includes(index) ? prevState.filter((i) => i !== index) : [...prevState, index]
    );
  };

  // 处理分页的改变
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // 解析result中的主要数据
  const renderResultData = (result) => {
    if (!result) return '无返回数据';
    return (
      <Box sx={{ ml: 2 }}>
        <Typography variant="body2"><strong>注册商:</strong> {result.registrar || 'N/A'}</Typography>
        <Typography variant="body2"><strong>创建日期:</strong> {convertToBeijingTime(result.creationDate)}</Typography>
        <Typography variant="body2"><strong>到期日期:</strong> {convertToBeijingTime(result.expirationDate)}</Typography>
        <Typography variant="body2"><strong>域名服务器:</strong> {result.nameServers?.join(', ') || 'N/A'}</Typography>
        <Typography variant="body2"><strong>DNSSEC状态:</strong> {parseDnssecStatus(result.dnssec)}</Typography>
        <Typography variant="body2"><strong>域名状态:</strong> {parseDomainStatus(result.status)}</Typography>
      </Box>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        域名Whois查询日志
      </Typography>

      {error && <Typography color="error">{error}</Typography>}

      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={handleDeleteOldLogs}
          disabled={deleting}
        >
          {deleting ? '正在删除...' : '删除一个月前的日志'}
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <Paper elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>域名</TableCell>
                <TableCell>用户名</TableCell>
                <TableCell>套餐</TableCell>
                <TableCell>查询IP</TableCell>
                <TableCell>查询时间</TableCell>
                <TableCell>状态</TableCell>
                <TableCell align="center">查看详情</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log, index) => (
                <React.Fragment key={log.log._id}>
                  <TableRow>
                    <TableCell>{log.log.domain}</TableCell>
                    <TableCell>{log.username || '未知用户'}</TableCell>
                    <TableCell>{log.package?.planName || '无套餐信息'}</TableCell>
                    <TableCell>{log.log.queriedIP || '未知IP'}</TableCell>
                    <TableCell>{convertToBeijingTime(log.log.createdAt)}</TableCell>
                    <TableCell>{log.log.status === 'success' ? '成功' : '失败'}</TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => toggleRowExpansion(index)}>
                        {expandedRows.includes(index) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={7} style={{ paddingBottom: 0, paddingTop: 0 }}>
                      <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Typography variant="h6" gutterBottom component="div">
                            返回数据详情:
                          </Typography>
                          <Paper sx={{ padding: 2, backgroundColor: '#f9f9f9' }}>
                            {renderResultData(log.log.result)}
                          </Paper>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}

      {/* 分页 */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Container>
  );
};

export default WhoisLogPage;
