import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Container, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../services/api';
import AlipayLogo from '../assets/alipay-logo.png';  // 确保你的 logo 路径正确

const Recharge = () => {
  const [amount, setAmount] = useState('');
  const [subject, setSubject] = useState('壹盾IP数据平台服务费');
  const [loading, setLoading] = useState(false);
  const [paymentForm, setPaymentForm] = useState('');
  const [error, setError] = useState('');
  const [selectedAmount, setSelectedAmount] = useState(null);

  // 生成唯一订单号
  const generateOutTradeNo = () => 'ORDER_' + Date.now();

  // 提交充值请求
  const handleRecharge = async () => {
    if (!amount || isNaN(amount) || amount <= 0) {
      setError('请输入有效的充值金额');
      return;
    }

    setLoading(true);
    setError('');

    const outTradeNo = generateOutTradeNo();
    const username = localStorage.getItem('username'); 
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_BASE_URL}/payment/create-payment`,
        {
          outTradeNo: outTradeNo,
          totalAmount: amount,
          subject: subject,
          username: username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success && response.data.paymentData.includes('<form')) {
        setPaymentForm(response.data.paymentData);
      } else {
        setError('生成支付链接失败，请稍后再试。');
      }
    } catch (err) {
      console.error('充值请求失败:', err);
      setError('充值请求失败，请稍后再试。');
    } finally {
      setLoading(false);
    }
  };

  // 点击固定充值金额时的处理函数
  const handleAmountClick = (value) => {
    setAmount(value);
    setSelectedAmount(value);
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          padding: 4,
          backgroundColor: '#f4f6f8',
          borderRadius: 3,
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          marginTop: '10vh',
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          账户充值
        </Typography>

        {/* 选择固定充值金额 */}
        <Box display="flex" justifyContent="center" gap={2} marginBottom={2}>
          {[10, 20, 50, 100, 200].map((value) => (
            <Button
              key={value}
              variant={selectedAmount === value ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => handleAmountClick(value)}
            >
              {value} 元
            </Button>
          ))}
        </Box>

        <Typography align="center" color="textSecondary">
          或输入自定义金额
        </Typography>

        <TextField
          label="充值金额 (元)"
          variant="outlined"
          fullWidth
          margin="normal"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
          sx={{ backgroundColor: '#fff', borderRadius: 2 }}
        />

        <Typography align="center" marginY={2} fontWeight="bold">
          支付方式
        </Typography>

        {/* 支付方式选择 */}
        <RadioGroup defaultValue="alipay" sx={{ justifyContent: 'center', display: 'flex' }}>
          <FormControlLabel
            value="alipay"
            control={<Radio checked />}
            label={
              <img
                src={AlipayLogo}  // 引入支付宝logo
                alt="Alipay"
                style={{ width: '150px', height: '50px', objectFit: 'contain' }}
              />
            }
          />
        </RadioGroup>

        {/* 支付表单 */}
        {paymentForm && (
          <div>
            <div dangerouslySetInnerHTML={{ __html: paymentForm }} style={{ display: 'none' }} />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ paddingY: 1.5, fontWeight: 'bold', marginTop: 2 }}
              onClick={() => {
                document.forms[0].submit();
              }}
            >
              立即支付
            </Button>
          </div>
        )}

        {error && (
          <Typography color="error" align="center" sx={{ marginTop: 2 }}>
            {error}
          </Typography>
        )}

        {!paymentForm && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ paddingY: 1.5, fontWeight: 'bold', marginTop: 2 }}
            onClick={handleRecharge}
          >
            提交充值
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default Recharge;
