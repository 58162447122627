import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Pagination,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import axios from 'axios';
import { API_BASE_URL } from '../services/api';
import { styled } from '@mui/system';
import { grey } from '@mui/material/colors';

const StyledTable = styled(Table)(({ theme }) => ({
  '& .MuiTableCell-head': {
    backgroundColor: theme?.palette?.primary?.light || '#f0f0f0',
    color: theme?.palette?.common?.white || '#000',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  '& .MuiTableCell-root': {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  '& .MuiTableRow-root:nth-of-type(odd)': {
    backgroundColor: theme?.palette?.action?.hover || '#fafafa',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: theme?.palette?.action?.selected || '#f5f5f5',
  },
  width: '100%',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  fontSize: '1rem',
}));

const ResultBox = styled(Box)(({ theme }) => ({
  backgroundColor: grey[100],
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  whiteSpace: 'pre-wrap',
  fontFamily: 'monospace',
  textAlign: 'left',
}));

const IcpLogsPage = () => {
  const [logs, setLogs] = useState([]); // 保存日志数据
  const [loading, setLoading] = useState(true); // 加载状态
  const [page, setPage] = useState(1); // 当前页
  const [totalPages, setTotalPages] = useState(1); // 总页数
  const [error, setError] = useState(''); // 错误消息
  const [expandedRow, setExpandedRow] = useState(null); // 控制展开的行
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // 控制清理弹窗显示
  const [openResultDialog, setOpenResultDialog] = useState(false); // 控制结果弹窗显示
  const [cleanupResult, setCleanupResult] = useState(''); // 保存清理结果

  // 获取 ICP 备案查询日志
  const fetchLogs = async (page = 1) => {
    setLoading(true);
    setError('');
    try {
      const res = await axios.get(`${API_BASE_URL}/admin/icp-logs`, {
        params: { page, limit: 10 },
        headers: { Authorization: localStorage.getItem('token') },
      });
      setLogs(res.data.data);
      setTotalPages(res.data.totalPages);
    } catch (error) {
      setError('获取日志数据失败，请稍后重试。');
    } finally {
      setLoading(false);
    }
  };

  // 初次加载时获取日志
  useEffect(() => {
    fetchLogs(page);
  }, [page]);

  // 处理分页变化并重置展开行
  const handlePageChange = (event, value) => {
    setPage(value);
    setExpandedRow(null); // 每次翻页重置展开行
  };

  // 切换展开和收起
  const toggleExpandRow = (rowIndex) => {
    setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
  };

  // 清理 1 个月前的日志
  const handleCleanup = async () => {
    setLoading(true);
    try {
      await axios.delete(`${API_BASE_URL}/admin/icp-logs/cleanup`, {
        headers: { Authorization: localStorage.getItem('token') },
      });
      setCleanupResult('日志清理成功！');
    } catch (error) {
      setCleanupResult('日志清理失败，请稍后重试。');
    } finally {
      setLoading(false);
      setOpenConfirmDialog(false); // 关闭确认弹窗
      setOpenResultDialog(true); // 显示清理结果弹窗
      fetchLogs(page); // 重新加载当前页数据
    }
  };

  // 打开确认弹窗
  const openConfirmCleanupDialog = () => {
    setOpenConfirmDialog(true);
  };

  // 关闭确认弹窗
  const closeConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  // 关闭结果弹窗
  const closeResultDialog = () => {
    setOpenResultDialog(false);
  };

  // 映射状态为中文
  const mapStatusToChinese = (status) => {
    switch (status) {
      case 'success':
        return '成功';
      case 'error':
        return '失败';
      case 'info':
        return '信息';
      default:
        return status;
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        ICP 备案查询接口日志
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {error && (
            <Box sx={{ mb: 3 }}>
              <Typography color="error">{error}</Typography>
            </Box>
          )}

          <StyledButton variant="contained" color="error" onClick={openConfirmCleanupDialog}>
            清理 1 个月前日志
          </StyledButton>

          {/* 清理确认弹窗 */}
          <Dialog
            open={openConfirmDialog}
            onClose={closeConfirmDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">确认清理日志</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                你确定要清理 1 个月前的所有 ICP 备案查询日志吗？此操作无法撤销。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeConfirmDialog}>取消</Button>
              <Button onClick={handleCleanup} color="error" autoFocus>
                确认
              </Button>
            </DialogActions>
          </Dialog>

          {/* 清理结果弹窗 */}
          <Dialog
            open={openResultDialog}
            onClose={closeResultDialog}
            aria-labelledby="result-dialog-title"
            aria-describedby="result-dialog-description"
          >
            <DialogTitle id="result-dialog-title">操作结果</DialogTitle>
            <DialogContent>
              <DialogContentText id="result-dialog-description">
                {cleanupResult}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeResultDialog} autoFocus>
                关闭
              </Button>
            </DialogActions>
          </Dialog>

          <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell>域名</TableCell>
                  <TableCell>查询 IP</TableCell>
                  <TableCell>Token</TableCell>
                  <TableCell>状态</TableCell>
                  <TableCell>创建时间</TableCell>
                  <TableCell>操作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log, index) => (
                  <React.Fragment key={log.log._id}>
                    <TableRow>
                      <TableCell>{log.log.domain}</TableCell>
                      <TableCell>{log.log.queriedIP}</TableCell>
                      <TableCell>{log.log.token}</TableCell>
                      <TableCell>{mapStatusToChinese(log.log.status)}</TableCell>
                      <TableCell>{new Date(log.log.createdAt).toLocaleString()}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => toggleExpandRow(index)}>
                          {expandedRow === index ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </TableCell>
                    </TableRow>

                    {/* 展开查看JSON内容 */}
                    {expandedRow === index && (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <ResultBox>
                            {typeof log.log.result === 'object'
                              ? JSON.stringify(log.log.result, null, 2)
                              : log.log.result}
                          </ResultBox>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </StyledTable>
          </Paper>

          {/* 分页组件 */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default IcpLogsPage;
