import React from 'react';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const CustomNavbar = ({ role, onLogout }) => {  // 从 props 获取 role 和 onLogout
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();  // 调用传入的 onLogout 函数
    navigate('/login');  // 退出后跳转到登录页面
  };

  return (
    <Navbar expand="lg" className="custom-navbar mb-1">
      <Container fluid>
        <Navbar.Brand as={Link} to="/" className="fw-bold">
          壹盾综合数据平台
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* 管理员权限显示的菜单 */}
            {role === 'admin' && (
              <>
                <Nav.Link as={Link} to="/admin" className="mx-2">
                  管理员首页
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/users" className="mx-2">
                  用户管理
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/add-ip-info" className="mx-2">
                  添加IP位置信息
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/manage-custom-ip" className="mx-2">
                  管理IP位置信息
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/review-corrections" className="mx-2">
                  审核IP纠错信息
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/free-ip-data-logs" className="mx-2">
                  开放API调用记录
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/whois-data-logs" className="mx-2">
                  域名Whois查询记录
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/icp-logs" className="mx-2">
                  域名ICP备案查询日志
                </Nav.Link>
              </>
            )}
            {/* 普通用户权限显示的菜单 */}
            {role === 'user' && (
              <>
                <Nav.Link as={Link} to="/user" className="mx-2">
                  用户首页
                </Nav.Link>
                <Nav.Link as={Link} to="/plans" className="mx-2">
                  套餐购买
                </Nav.Link>
                <Nav.Link as={Link} to="/mypackage" className="mx-2">
                  我的套餐
                </Nav.Link>
                <Nav.Link as={Link} to="/ip-data-logs" className="mx-2">
                  调用日志
                </Nav.Link>
                <Nav.Link as={Link} to="/recharge" className="mx-2">
                  账户充值
                </Nav.Link>
                <Nav.Link as={Link} to="/bills" className="mx-2">
                  账单管理
                </Nav.Link>
                <Nav.Link as={Link} to="/docs" className="mx-2">
                  接口文档
                </Nav.Link>
                <Nav.Link as={Link} to="/ip-correction" className="mx-2">
                  IP信息纠错
                </Nav.Link>
              </>
            )}
          </Nav>
          {role && (
            <Button
              variant="outline-danger"
              className="ms-2 custom-logout-btn"
              onClick={handleLogout}
              style={{ borderRadius: '25px', padding: '5px 15px' }}
            >
              退出登录
            </Button>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
