import React, { useState } from 'react';
import { Container, Typography, Box, TextField, Button, Grid, Alert, Paper } from '@mui/material';
import axios from 'axios';

const AddIPData = () => {
  const [ipRange, setIPRange] = useState('');
  const [country, setCountry] = useState('');
  const [regions, setRegions] = useState('');
  const [asName, setAsName] = useState('');
  const [asNumber, setAsNumber] = useState('');
  const [type, settype] = useState('');
  const [message, setMessage] = useState('');

  const handleAddIP = async () => {
    if (!ipRange || !country || !regions || !asName || !type || !asNumber) {
      setMessage('所有字段都必须填写');
      return;
    }

    try {
      const response = await axios.post(
        `https://api.ip.setonink.com/data/add-ip`,
        { ipRange, country, regions: regions.split(','), asName, type, asNumber }, // 将regions分隔为数组
        {
            headers: { Authorization: localStorage.getItem('token') },
        }
      );

      if (response.status === 201) {
        setMessage('自定义 IP 数据添加成功');
        // 清空输入
        setIPRange('');
        setCountry('');
        setRegions('');
        setAsName('');
        setAsNumber('');
        settype('');
      } else {
        setMessage('添加失败，请检查输入');
      }
    } catch (err) {
      console.error('添加自定义 IP 数据失败:', err);
      setMessage('服务器错误，添加失败');
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Paper elevation={3} sx={{ p: 4, backgroundColor: '#fafafa' }}>
        <Box sx={{ mb: 3, textAlign: 'center' }}>
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            添加自定义 IP 数据
          </Typography>
          <Typography variant="body1" color="text.secondary">
            请确保填写所有必填项，以添加新的 IP 数据到数据库。
          </Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="IP 段 (例如: 192.168.0.0/24)"
              value={ipRange}
              onChange={(e) => setIPRange(e.target.value)}
              variant="outlined"
              required
              sx={{ backgroundColor: '#fff', borderRadius: 1 }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="国家"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              variant="outlined"
              required
              sx={{ backgroundColor: '#fff', borderRadius: 1 }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="地区 (用英文逗号分隔)"
              value={regions}
              onChange={(e) => setRegions(e.target.value)}
              variant="outlined"
              required
              sx={{ backgroundColor: '#fff', borderRadius: 1 }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="AS编号"
              value={asNumber}
              onChange={(e) => setAsNumber(e.target.value)}
              variant="outlined"
              required
              sx={{ backgroundColor: '#fff', borderRadius: 1 }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="运营商"
              value={asName}
              onChange={(e) => setAsName(e.target.value)}
              variant="outlined"
              required
              sx={{ backgroundColor: '#fff', borderRadius: 1 }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="IP 类型"
              value={type}
              onChange={(e) => settype(e.target.value)}
              variant="outlined"
              required
              sx={{ backgroundColor: '#fff', borderRadius: 1 }}
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                px: 5,
                py: 1.5,
                backgroundColor: '#1976d2',
                fontWeight: 'bold',
                borderRadius: 2,
                '&:hover': {
                  backgroundColor: '#1565c0',
                },
              }}
              onClick={handleAddIP}
            >
              添加自定义 IP 数据
            </Button>
          </Grid>
        </Grid>

        {message && (
          <Box sx={{ mt: 4 }}>
            <Alert severity={message.includes('成功') ? 'success' : 'error'}>{message}</Alert>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default AddIPData;
