import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';
import AdminDashboard from './pages/AdminDashboard';
import UserDashboard from './pages/UserDashboard';
import UserManagement from './pages/UserManagement';
import CustomNavbar from './components/Navbar';
import Recharge from './pages/Recharge';
import Bills from './pages/Bills';
import Plans from './pages/Plans';
import MyPackage from './pages/MyPackage';
import AddIPData from './pages/AddIPData';
import ManageCustomIP from './pages/ManageCustomIP';
import Documentation from './pages/Documentation';
import IPCorrection from './pages/IPCorrection';
import ReviewCorrections from './pages/ReviewCorrections';
import IPDataLogs from './pages/IPDataLogs';
import FreeIpDataLogs from './pages/FreeIpDataLogs';
import WhoisLogPage from './pages/WhoisLogPage';
import IcpLogsPage from './pages/IcpLogsPage';
import axios from 'axios';
import { API_BASE_URL } from './services/api';
import { CircularProgress, Box } from '@mui/material';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const [role, setRole] = useState(null);  // 保存用户角色
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);  // 添加一个加载状态

  // 用于检查用户是否登录
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios
        .get(`${API_BASE_URL}/auth/profile`, {
          headers: { Authorization: token },
        })
        .then((res) => {
          setRole(res.data.role); // 设置角色
          setIsLoggedIn(true); // 设置为已登录
        })
        .catch(() => {
          setIsLoggedIn(false); // 请求失败则认为未登录
        })
        .finally(() => {
          setLoading(false); // 加载完成
        });
    } else {
      setLoading(false); // 没有 token 也设置为加载完成
    }

    // Axios 拦截器，处理 token 过期
    const interceptor = axios.interceptors.response.use(
      (response) => response, // 成功响应直接返回
      (error) => {
        if (error.response && error.response.status === 401) {
          // 如果返回 401，说明 token 失效
          handleLogout(); // 调用退出登录逻辑
          return <Navigate to="/login" />;
        }
        return Promise.reject(error);
      }
    );

    // 清理拦截器
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token'); // 移除 token
    localStorage.removeItem('role'); // 移除 role
    setIsLoggedIn(false); // 设置为未登录
    setRole(null); // 清除角色信息
    window.location.href = '/login'; // 重定向到登录页面
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Router>
      <div>
        {isLoggedIn && <CustomNavbar role={role} onLogout={handleLogout} />}

        <Routes>
          {/* 如果用户已登录，根据角色重定向到相应的首页 */}
          <Route
            path="/"
            element={
              isLoggedIn ? (
                role === 'admin' ? <Navigate to="/admin" /> : <Navigate to="/user" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          {/* 公共路由 */}
          <Route
            path="/register"
            element={
              isLoggedIn ? (
                role === 'admin' ? <Navigate to="/admin" /> : <Navigate to="/user" />
              ) : (
                <Register />
              )
            }
          />
          <Route
            path="/login"
            element={
              isLoggedIn ? (
                role === 'admin' ? <Navigate to="/admin" /> : <Navigate to="/user" />
              ) : (
                <Login setRole={setRole} setIsLoggedIn={setIsLoggedIn} />
              )
            }
          />
          <Route path="/ip-correction" element={<IPCorrection />} />
          <Route path="/docs" element={<Documentation />} />

          {/* 受保护的管理员路由 */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['admin']}>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/users"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['admin']}>
                <UserManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/add-ip-info"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['admin']}>
                <AddIPData />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/manage-custom-ip"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['admin']}>
                <ManageCustomIP />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/review-corrections"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['admin']}>
                <ReviewCorrections />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/free-ip-data-logs"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['admin']}>
                <FreeIpDataLogs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/whois-data-logs"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['admin']}>
                <WhoisLogPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/icp-logs"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['admin']}>
                <IcpLogsPage />
              </ProtectedRoute>
            }
          />

          {/* 受保护的用户路由 */}
          <Route
            path="/user"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['user']}>
                <UserDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/recharge"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['user']}>
                <Recharge />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bills"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['user']}>
                <Bills />
              </ProtectedRoute>
            }
          />
          <Route
            path="/plans"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['user']}>
                <Plans />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mypackage"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['user']}>
                <MyPackage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ip-data-logs"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} role={role} allowedRoles={['user']}>
                <IPDataLogs />
              </ProtectedRoute>
            }
          />

          {/* 未匹配到的路由重定向到根路径，根据登录状态处理 */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
