import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Box, MenuItem, TextField, Table, TableBody, TableCell, TableHead, TableRow, Collapse, IconButton, Paper, Pagination, InputAdornment } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material';
import axios from 'axios';
import { API_BASE_URL } from '../services/api';

const LogDataPage = () => {
  const [tokens, setTokens] = useState([]); // 存储用户所有的 tokens
  const [selectedToken, setSelectedToken] = useState(''); // 用户选择的 token
  const [interfaceType, setInterfaceType] = useState('ip-query'); // 接口类型，默认为IP查询接口
  const [logs, setLogs] = useState([]); // 存储查询到的日志
  const [loading, setLoading] = useState(false); // 加载状态
  const [error, setError] = useState(''); // 错误状态
  const [expandedRows, setExpandedRows] = useState([]); // 控制折叠面板的展开状态
  const [page, setPage] = useState(1); // 当前页
  const [totalPages, setTotalPages] = useState(1); // 总页数
  const [searchQuery, setSearchQuery] = useState(''); // 搜索关键字

  // 获取用户的所有 tokens
  const fetchTokens = useCallback(async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${API_BASE_URL}/mypackage/my-packages`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { interfaceType } // 传入接口类型
      });
      const userTokens = response.data.userPackages.map((pkg) => pkg.token);
      setTokens(userTokens);
    } catch (err) {
      console.error('获取 tokens 失败:', err);
    }
  }, [interfaceType]);

  // 获取日志记录
  const fetchLogs = async (token, page = 1, search = '') => {
    setLoading(true);
    setError('');
    const authToken = localStorage.getItem('token');
    let logEndpoint;

    // 根据接口类型选择日志接口
    switch (interfaceType) {
      case 'ip-query':
        logEndpoint = 'ipdatalogs/user-logs';
        break;
      case 'domain-whois':
        logEndpoint = 'domainwhoislogs/user-logs';
        break;
      case 'icp':
        logEndpoint = 'domainicplogs/user-logs';
        break;
      default:
        logEndpoint = 'ipdatalogs/user-logs';
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/${logEndpoint}`, {
        params: { token, page, search },
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setLogs(response.data.logs);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (err) {
      console.error('获取日志记录失败:', err);
      setError('获取日志记录失败');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTokens(); // 加载用户的 tokens
  }, [interfaceType, fetchTokens]); // 当接口类型变化时重新获取 tokens

  // 选择 token 时更新日志
  const handleTokenChange = (event) => {
    const token = event.target.value;
    setSelectedToken(token);
    setPage(1);
    fetchLogs(token, 1, searchQuery);
  };

  // 切换折叠面板展开状态
  const toggleRowExpansion = (index) => {
    setExpandedRows((prevState) =>
      prevState.includes(index)
        ? prevState.filter((i) => i !== index)
        : [...prevState, index]
    );
  };

  // 处理分页改变
  const handlePageChange = (event, value) => {
    setPage(value);
    fetchLogs(selectedToken, value, searchQuery);
  };

  // 处理搜索
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setPage(1);
    fetchLogs(selectedToken, 1, event.target.value);
  };

  // 处理接口类型改变
  const handleInterfaceTypeChange = (event) => {
    setInterfaceType(event.target.value);
    setSelectedToken(''); // 重置 token
    setLogs([]); // 清空日志
  };

  // 动态渲染表格列标题
  const renderTableHeaders = () => {
    switch (interfaceType) {
      case 'ip-query':
        return (
          <TableRow>
            <TableCell>查询 IP</TableCell>
            <TableCell>请求来源</TableCell>
            <TableCell>调用 IP</TableCell>
            <TableCell>是否成功</TableCell>
            <TableCell>扣除次数</TableCell>
            <TableCell>调用时间</TableCell>
            <TableCell align="center">查看详情</TableCell>
          </TableRow>
        );
      case 'domain-whois':
        return (
          <TableRow>
            <TableCell>查询域名</TableCell>
            <TableCell>查询 IP</TableCell>
            <TableCell>是否成功</TableCell>
            <TableCell>状态信息</TableCell>
            <TableCell>调用时间</TableCell>
            <TableCell align="center">查看详情</TableCell>
          </TableRow>
        );
      case 'icp':
        return (
          <TableRow>
            <TableCell>查询域名</TableCell>
            <TableCell>调用 IP</TableCell>
            <TableCell>调用 Token</TableCell>
            <TableCell>状态</TableCell>
            <TableCell>扣除次数</TableCell>
            <TableCell>调用时间</TableCell>
            <TableCell align="center">查看详情</TableCell>
          </TableRow>
        );
      default:
        return null;
    }
  };

  // 动态渲染表格内容
  const renderTableRows = (log, index) => {
    switch (interfaceType) {
      case 'ip-query':
        return (
          <React.Fragment key={log._id || index}>
            <TableRow>
              <TableCell
                sx={{
                  maxWidth: '200px', // 限制列的最大宽度
                  wordWrap: 'break-word', // 自动换行
                  overflowWrap: 'break-word', // 支持超长单词换行
                }}
              >
                {log.queriedIP}
              </TableCell>
              <TableCell>{log.referrer}</TableCell>
              <TableCell>{log.realIP}</TableCell>
              <TableCell>{log.isSuccess ? '成功' : '失败'}</TableCell>
              <TableCell>{log.deductedCalls}</TableCell>
              <TableCell>{new Date(log.createdAt).toLocaleString()}</TableCell>
              <TableCell align="center">
                <IconButton size="small" onClick={() => toggleRowExpansion(index)}>
                  {expandedRows.includes(index) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <Paper elevation={3} sx={{ padding: '10px' }}>
                      <pre>{JSON.stringify(log.result, null, 2)}</pre>
                    </Paper>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      case 'domain-whois':
        return (
          <React.Fragment key={log._id || index}>
            <TableRow>
              <TableCell>{log.domain}</TableCell>
              <TableCell>{log.queriedIP}</TableCell>
              <TableCell>{log.status === 'success' ? '成功' : '失败'}</TableCell>
              <TableCell>{log.message}</TableCell>
              <TableCell>{new Date(log.createdAt).toLocaleString()}</TableCell>
              <TableCell align="center">
                <IconButton size="small" onClick={() => toggleRowExpansion(index)}>
                  {expandedRows.includes(index) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <Paper elevation={3} sx={{ padding: '10px' }}>
                      <pre>{JSON.stringify(log.result, null, 2)}</pre>
                    </Paper>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      case 'icp':
        return (
          <React.Fragment key={log._id || index}>
            <TableRow>
              <TableCell>{log.domain}</TableCell>
              <TableCell>{log.queriedIP}</TableCell>
              <TableCell>{log.token}</TableCell>
              <TableCell>{log.status === 'success' ? '成功' : '失败'}</TableCell>
              <TableCell>{log.deductedCalls}</TableCell>
              <TableCell>{new Date(log.createdAt).toLocaleString()}</TableCell>
              <TableCell align="center">
                <IconButton size="small" onClick={() => toggleRowExpansion(index)}>
                  {expandedRows.includes(index) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <Paper elevation={3} sx={{ padding: '10px' }}>
                      <pre>{JSON.stringify(log.result, null, 2)}</pre>
                    </Paper>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" gutterBottom align="center">
          查询调用日志
        </Typography>

        {/* 接口类型选择下拉菜单 */}
        <TextField
          select
          label="选择接口类型"
          value={interfaceType}
          onChange={handleInterfaceTypeChange}
          variant="outlined"
          sx={{ mb: 2, width: '300px' }}
        >
          <MenuItem value="ip-query">IP查询接口</MenuItem>
          <MenuItem value="domain-whois">域名Whois查询接口</MenuItem>
          <MenuItem value="icp">ICP备案查询接口</MenuItem>
        </TextField>

        {/* Token 选择下拉菜单 */}
        <TextField
          select
          label="选择 Token"
          value={selectedToken}
          onChange={handleTokenChange}
          variant="outlined"
          sx={{ mb: 2, width: '300px' }}
        >
          {tokens.map((token, index) => (
            <MenuItem key={token} value={token}>  {/* key 设为唯一的 token */}
              {token}
            </MenuItem>
          ))}
        </TextField>

        {/* 搜索框 */}
        <TextField
          label="搜索查询 IP 或域名"
          fullWidth
          value={searchQuery}
          onChange={handleSearch}
          variant="outlined"
          sx={{ mb: 3 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        {/* 错误信息显示 */}
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        {/* 显示日志记录表格 */}
        <Table sx={{ tableLayout: 'fixed', borderCollapse: 'separate', borderSpacing: '0 10px', width: '100%' }}>
          <TableHead>
            {renderTableHeaders()}
          </TableHead>
          <TableBody>
            {logs.map((log, index) => renderTableRows(log, index))}
          </TableBody>
        </Table>

        {/* 分页控制 */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            sx={{ mt: 2 }}
          />
        </Box>

        {/* 没有日志时显示 */}
        {logs.length === 0 && !loading && (
          <Typography align="center" sx={{ mt: 3 }}>
            暂无日志记录。
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default LogDataPage;
